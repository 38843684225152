export default function handleSendingIfBlocked({ data, popupAlert }) {
  if (data.loggedInUserIsBlocked) {
    popupAlert("The message can't be sent because you have been blocked.");

    return true;
  }

  if (data.oppositeUserIsBlocked) {
    popupAlert(
      "The message can't be sent because you have blocked the other person."
    );

    return true;
  }

  return false;
}
