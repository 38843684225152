import styled from "styled-components";
import WhyChooseUsLine from "./WhyChooseUsLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 65px;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 35px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default function WhyChooseUsText2() {
  return (
    <Main>
      <Container>
        <Tagline>
          Become A Professional <br /> With Our
        </Tagline>
        <Buttons>
          <WhyChooseUsLine> Distinguished Mentors </WhyChooseUsLine>
          <WhyChooseUsLine>Industrial Projects</WhyChooseUsLine>
          <WhyChooseUsLine>Live Interactive Session</WhyChooseUsLine>
          <WhyChooseUsLine>Internship Experience</WhyChooseUsLine>
        </Buttons>
        <br />
      </Container>
    </Main>
  );
}
