import { useContext, useState } from "react";
import styled from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import goTo from "../../controllers/goTo";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import getHowOldInString from "../../controllers/utils/getHowOldInString";

const Main = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  /* border-bottom: 1px solid var(--translucentHard); */
  gap: 15px;
  background: var(--glassGradientHard);
  box-shadow: rgb(1 141 226 / 28%) 0px 2px 2px 0px;
  border-radius: 10px;
  border: 1px solid var(--foreground);

  backdrop-filter: contrast(1) brightness(1.05);
`;

const ProfileImage = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 5px;
  object-fit: cover;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 5px;
  width: calc(100% - 70px);

  /* padding-top: 5px; */
  /* background-color: var(--foreground);
  box-shadow: var(--lightShadow);
  border-radius: 0 15px 15px 15px;
  padding: 15px 20px; */
`;

const Content2 = styled.div`
  font-size: 15px;
  padding: 15px 25px;
  opacity: 0.6;
  background-color: transparent;
  border-bottom: 1px solid var(--translucentHard);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MessageSpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 40px;
  padding-top: 0;
  gap: 10px;
`;

const MessageSpace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--translucentHard);
  /* background-color: var(--translucentLight); */
  border-radius: 15px;
`;

const WriteMessage = styled.input`
  height: 100%;
  resize: none;
  width: 100%;
  border: none;

  padding: 0;
  padding-left: 20px;
  color: var(--color);
  background-color: transparent;
  font-size: 16px;
`;

const ChatBubble = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 80px);
  background: #fff;
  position: relative;
  padding: 10px;
  border-radius: 7px 7px 7px 7px;
  width: 100%;
  box-shadow: 1px 1px 7px 0px #5893bc7d;

  &:after {
    content: "";
    position: absolute;
    top: 5px;
    background: #fff0;
    left: -18px;
    /* height: 10px; */
    /* width: 30px; */
    /* border-radius: 0 0 0 100px; */
    border: 10px;
    border-left: 10px solid #ff000000;
    border-bottom: 10px solid #b08e8e00;
    border-right: 12px solid #fffffffa;
    border-top: 10px solid #741c1c00;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
`;

const Sent = styled.div`
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background: var(--glassGradientHard);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-shadow: var(--lightShadow);
  border: 1px solid var(--foreground);
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 13px;
`;

const Text = styled.div`
  font-size: 12px;
  opacity: 0.6;
  font-weight: 500;
`;

export default function FeedItemMobile({ item }) {
  const [isDeleted, setIsDeleted] = useState(null);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (!item.author) {
    item.author = { name: "Anonymous", bio: "", profileImage: null };
  }

  if (isDeleted) return null;

  return (
    <Main onClick={goTo(`/content/${item._id}`)}>
      <ProfileImage src={getImageURL(item.author.profileImage, true)} />
      <Content>
        <Title>{capitalizeFirstLetter(item.title)}</Title>
        <Text>
          {item.author.username} . {getHowOldInString(item.createdAt)}
        </Text>
      </Content>
    </Main>
  );
}
