import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";

import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { serverLine } from "../../controllers/serverLine";
import openLoginPopup from "../../controllers/openLoginPopup";
import Context from "../../Context";
import CustomButton from "../helperComponents/CustomButton";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function LikeDislikeButtons({
  likeStatus,
  likeCount = 0,
  dislikeCount = 0,
  contentID,
  commentID,
  replyLocation,
  variant = "outlined",
  style,
  isMinimal,
  type,
}) {
  const { loggedInUserID, globalLikeStatus, updateGlobalLikeStatus } =
    useContext(Context);

  const [statusForComment, setStatusForComment] = useState(
    likeStatus ? likeStatus : "NEUTRAL"
  );
  const [likeCountState, setLikeCountState] = useState(0);
  const [dislikeCountState, setDislikeCountState] = useState(0);

  let status = getStatus();

  useEffect(() => {
    if (type == "CONTENT") {
      if (globalLikeStatus[contentID]) {
        setLikeCountState(globalLikeStatus[contentID].likeCount);
        setDislikeCountState(globalLikeStatus[contentID].dislikeCount);
      }
    } else {
      if (typeof likeCount == "number") {
        setLikeCountState(likeCount);
      }

      if (typeof dislikeCount == "number") {
        setDislikeCountState(dislikeCount);
      }
    }
  }, [globalLikeStatus, contentID]);

  if (commentID) status = statusForComment;

  if (replyLocation) commentID = null;
  let details = { contentID, commentID, replyLocation };

  let dislikeButton = (
    <CustomButton
      style={style}
      variant={variant}
      onClick={dislike}
      icon={status === "DISLIKE" ? <AiFillDislike /> : <AiOutlineDislike />}
    >
      {dislikeCountState.toString()}
    </CustomButton>
  );

  if (isMinimal) {
    dislikeButton = null;

    if (window.innerWidth < 900) {
      style = {
        fontSize: "15px",
        height: "35px",
        padding: "10px 15px",
        gap: "10px",
      };
    }
  }

  // return status;

  // return [status, status, contentID, JSON.stringify(globalLikeStatus)];

  return (
    <Buttons>
      <CustomButton
        style={style}
        variant={variant}
        onClick={like}
        icon={status === "LIKE" ? <AiFillLike /> : <AiOutlineLike />}
      >
        {likeCountState.toString()}
      </CustomButton>

      {dislikeButton}
    </Buttons>
  );

  function setStatus(newStatus, newLikeCount, newDislikeCount) {
    if (type === "CONTENT") {
      updateGlobalLikeStatus([
        {
          _id: contentID,
          likeStatus: newStatus,
          likeCount: newLikeCount,
          dislikeCount: newDislikeCount,
        },
      ]);
    } else {
      setStatusForComment(newStatus);
    }
  }

  function getStatus() {
    if (type == "CONTENT") {
      if (globalLikeStatus[contentID])
        return globalLikeStatus[contentID].likeStatus;

      return "NEUTRAL";
    } else {
      return statusForComment;
    }
  }

  function like() {
    if (!loggedInUserID) return openLoginPopup();

    serverLine.post("/like-dislike", { ...details, type: "LIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;
    let newStatus = null;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      toIncrementLikeCount++;
      newStatus = "LIKE";
    } else if (status === "LIKE") {
      toIncrementLikeCount--;

      newStatus = "NEUTRAL";
    } else if (status === "NEUTRAL") {
      toIncrementLikeCount++;

      newStatus = "LIKE";
    }

    let newLikeCount = likeCountState + toIncrementLikeCount;
    let newDislikeCount = dislikeCountState + toIncrementDislikeCount;
    setStatus(newStatus, newLikeCount, newDislikeCount);
    setLikeCountState(newLikeCount);
    setDislikeCountState(newDislikeCount);
  }

  function dislike() {
    if (!loggedInUserID) return openLoginPopup();
    serverLine.post("/like-dislike", { ...details, type: "DISLIKE" });

    let toIncrementLikeCount = 0;
    let toIncrementDislikeCount = 0;
    let newStatus = null;

    if (status === "DISLIKE") {
      toIncrementDislikeCount--;
      newStatus = "NEUTRAL";
    } else if (status === "LIKE") {
      toIncrementLikeCount--;
      toIncrementDislikeCount++;
      newStatus = "DISLIKE";
    } else if (status === "NEUTRAL") {
      toIncrementDislikeCount++;
      newStatus = "DISLIKE";
    }

    let newLikeCount = likeCountState + toIncrementLikeCount;
    let newDislikeCount = dislikeCountState + toIncrementDislikeCount;

    setStatus(newStatus, newLikeCount, newDislikeCount);
    setLikeCountState(newLikeCount);
    setDislikeCountState(newDislikeCount);
  }
}
