import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { serverLine } from "../../controllers/serverLine";
import Context from "../../Context";
import NotificationBox from "./NotificationBox";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import goTo from "../../controllers/goTo";
import LoadingSection from "../helperComponents/LoadingSection";
import goToProfile from "../../controllers/goToProfile";
import SemanticButton from "../helperComponents/SemanticButton";
import getProfileLink from "../../controllers/getProfileLink";

const Notification = styled.div`
  background: var(--translucent);
  padding: 25px 25px;
  /* cursor: pointer; */
  width: 100%;
  border-radius: 15px;

  /* text-transform: capitalize; */
  background: var(--translucent);
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--color);
      color:var(--bgColor);`;
  }}
`;

const Button = styled.button`
  /* flex: 1; */
  position: relative;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  box-shadow: var(--lightShadow);
  border: none;
  font-size: 15px;
  padding: 10px 25px;
  color: var(--color);
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.05s ease-in-out;

  &:hover {
    transform: scale(0.95);
  }

  ${({ seen }) => {
    if (!seen)
      return `
 

      color: var(--color);
      background-color: var(--bgColor);
      border: 1px solid var(--color);

  
      
      `;
  }}
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  opacity: 0.5;
`;

export default function NotificationsCore({ scrollData }) {
  const { loggedInUserID, loggedInUser, updateNotificationData } =
    useContext(Context);
  const [res, setRes] = useState([]);
  const [notificationsSeenAt, setNotificationsSeenAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  let api = "/notifications";

  useEffect(() => {
    setOffset(0);
    setNotificationsSeenAt(null);
    setRes([]);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      doLoading(true).then(updateNotificationData);
    }
  }, [loggedInUserID]);

  async function doLoading(firstLoad, theOffset = offset) {
    if (firstLoad) theOffset = 0;
    setLoading(true);
    let newRes = await serverLine.get(`${api}/?offset=${theOffset}`);

    if (firstLoad) {
      setNotificationsSeenAt(newRes.notificationsSeenAt);
    }
    if (!newRes.notifs.length) {
      setReachedTheEnd(true);
    }

    if (firstLoad) {
      setRes([...newRes.notifs]);
    } else {
      setRes([...res, ...newRes.notifs]);
    }

    setLoading(false);

    setOffset(theOffset + 1);
    return true;
  }

  if (!loggedInUserID) return "Login to continue";

  if (!loggedInUser) return <LoadingSection />;

  let result = renderResult();

  if (!result.length && !loading) return <Message>No alerts to show</Message>;

  return (
    <InfiniteScroll
      reachedTheEnd={reachedTheEnd}
      loading={loading}
      load={doLoading}
      scrollData={scrollData}
    >
      {result}
    </InfiniteScroll>
  );

  function renderResult() {
    if (!res) return [];
    if (!res.length) return [];
    return res.map((item) => {
      return getNotif({ item });
    });
  }

  function getNotif({ item }) {
    let seen = hasSeen(item.createdAt);

    let notificationText = null;
    let notificationImage = null;
    let notificationKey = item._id;
    let createdAt = item.createdAt;
    let notificationButtons = null;

    if (item.type === "CONTENT_REPORTED") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.postData) {
        notificationText = `Your post: '${
          item.postData.title
        }' got reported. Reason: ${item.data ? item.data.reason : ""}`;
      } else {
        notificationText = `Your Content got reported.`;
      }
    } else if (item.type === "USER_REPORTED") {
      notificationButtons = null;

      notificationText = `You got reported. Reason: ${
        item.data ? item.data.reason : ""
      }`;
    } else if (item.type === "CONTENT_UN_BANNED") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.content) {
        notificationText = `Your post: '${item.postData.title}' has been un-banned.`;
      } else {
        notificationText = `Your Content got un-banned.`;
      }
    } else if (item.type === "CONTENT_BANNED") {
      notificationButtons = (
        <>
          <SemanticButton
            onClick={goTo("/content/" + item.contentID)}
            semanticHref={"/content/" + item.contentID}
          >
            <Button seen={seen}>Visit Content</Button>
          </SemanticButton>
        </>
      );

      if (item.content) {
        notificationText = `Your content '${item.postData.title}' has been banned.`;
      } else {
        notificationText = `Your Content got banned.`;
      }
    } else if (item.type === "USER_BANNED") {
      notificationButtons = null;

      notificationText = `You have been banned`;
    } else {
      // notificationImage = item.sender.profileImage;
      notificationButtons = null;
      notificationText = `${item.sender ? item.sender.username : null} - ${
        item.type
      }`;
    }

    return (
      <NotificationBox
        key={notificationKey}
        createdAt={createdAt}
        seen={seen}
        text={notificationText}
        buttons={notificationButtons}
        image={notificationImage}
      />
    );
  }

  function hasSeen(createdAt) {
    let createdAtEpochs = new Date(createdAt).valueOf();
    let notificationsSeenAtDate = new Date(
      notificationsSeenAt ? notificationsSeenAt : 0
    ).valueOf();
    // console.log(createdAtEpochs, notificationsSeenAt);

    return createdAtEpochs < notificationsSeenAtDate;
  }
}
