import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import CustomToggle from "../../helperComponents/CustomToggle";
import Context from "../../../Context";
import { useParams, useSearchParams } from "react-router-dom";

import parseScrollData from "../../../controllers/parseScrollData";
import getUrlParams from "../../../controllers/getUrlParams";

import ContentFeed from "../../feed/ContentFeed";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  /* align-items: center; */
  align-items: center;
  /* justify-content: center; */
  gap: 50px;
  height: 100%;

  overflow-y: scroll;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0px 200px;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 10px;
  }
`;

export default function FeedPage() {
  const { loggedInUser, setLoggedInUser, currentRoute } = useContext(Context);

  const [scrollData, setScrollData] = useState(null);

  let urlParams = getUrlParams(currentRoute);

  let type = urlParams.get("type");
  let filterType = urlParams.get("filterType");

  let content = (
    <ContentFeed
      scrollData={scrollData}
      initialFilterType={filterType}
      type={type}
      mode="INFINITE"
      // title={title}
      loadSecondIndexAsWell={true}
    />
  );

  return (
    <WithHeader>
      <Container onScroll={parseScrollData(setScrollData)}>
        <Main>{content}</Main>
        <br /> <br /> <br /> <br />
      </Container>
    </WithHeader>
  );
}
