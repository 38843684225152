import { useContext } from "react";
import Context from "../../Context";
import MyMessage from "./MyMessage";
import TheirMessage from "./TheirMessage";
import { styled } from "styled-components";
import getImageURL from "../../controllers/getImageURL";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage";

const Content = styled.p`
  margin: 0;
  /* white-space: pre; */
`;

const Image = styled.img`
  width: 300px;
  min-height: 100px;
  // If min-height is not set, chat scroll to bottom won't work because image takes time to load
  border-radius: 10px;
`;

export default function MessageBox({ message, group, oppositeMember }) {
  const { loggedInUserID } = useContext(Context);

  let content = null;

  if (message.message.text) {
    content = <Content>{message.message.text}</Content>;
  }

  if (message.message.image) {
    let theImage = message.message.image;

    if (theImage.type == "LOCAL_IMAGE") {
      console.log(window.localImages[theImage.data], "IMAGE_BLOBBBBB");

      content = (
        <ShowSelectedImage
          width="300px"
          height="auto"
          borderRadius="10px"
          fileObject={window.localImages[theImage.data]}
        />
      );
    } else {
      content = <Image src={getImageURL(message.message.image)} />;
    }
  }

  if (message.authorUserID == oppositeMember._id)
    return (
      <TheirMessage
        message={message}
        content={content}
        group={group}
        oppositeMember={oppositeMember}
      />
    );

  return (
    <MyMessage
      message={message}
      group={group}
      oppositeMember={oppositeMember}
      content={content}
    />
  );
}
