export default function getUnreadMessageCount(groupItem) {
  if (!groupItem.myID) return 0;

  if (groupItem.notificationData) {
    let notifData = groupItem.notificationData[groupItem.myID];
    if (notifData) {
      if (notifData.unreadMessages) {
        return notifData.unreadMessages;
      }
    }
  }

  return 0;
}
