import { styled } from "styled-components";

import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import Context from "../../../Context";

import getSubPath from "../../../controllers/getSubPath";
import RenderContent from "../../contentPage/RenderContent";
import LoadingSection from "../../helperComponents/LoadingSection";
import CreateContent from "./CreateContent";
import BeautifulTitle from "../../helperComponents/BeautifulTitle";
import { MdOutlineEdit } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export default function EditContent() {
  const { currentRoute } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    let contentID = getSubPath(1, currentRoute);
    serverLine.get("/content/?contentID=" + contentID).then((newData) => {
      setPostData(newData);
      setLoading(false);
    });
  }, [currentRoute]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <WithHeader>
      <Container>
        <Main>
          <BeautifulTitle icon={<MdOutlineEdit />}>Update Post</BeautifulTitle>
          <CreateContent contentToUpdate={postData} />
          <br /> <br /> <br /> <br /> <br /> <br />
        </Main>
      </Container>
    </WithHeader>
  );
}
