import { useContext, useEffect, useState } from "react";
import WithHeader from "../core/WithHeader";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import CustomToggle from "../helperComponents/CustomToggle";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import FeedItem from "../cardForFeed/FeedItem";
import UserBox from "../profilePage/UserBox";
import styled from "styled-components";
import Context from "../../Context";
import getUrlParams from "../../controllers/getUrlParams";
import ReportBox from "./ReportBox";
import CustomButton from "../helperComponents/CustomButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 50px;
  width: 45vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

export default function ShowReportsPage() {
  const { currentRoute, popupAlert } = useContext(Context);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  let urlQuery = getUrlParams(currentRoute);

  useEffect(() => {
    serverLine
      .get(
        `/reports/?subjectID=${urlQuery.get(
          "subjectID"
        )}&subjectType=${urlQuery.get("subjectType")}`
      )
      .then((newData) => {
        setData(newData);
        setLoading(false);
      });
  }, []);

  if (loading || !data)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <BeautifulTitle>Reports</BeautifulTitle>

        <Main>
          <Content>
            {urlQuery.get("subjectType") == "CONTENT" ? (
              <FeedItem showReportsData={true} item={data.reportItem} />
            ) : (
              <UserBox showReportsData={true} item={data.reportItem} />
            )}
          </Content>

          <Buttons>
            <CustomButton variant="filled" onClick={banItem}>
              {" "}
              Ban Item
            </CustomButton>

            {urlQuery.get("subjectType") == "CONTENT" ? (
              <CustomButton variant="filled" onClick={banAuthor}>
                {" "}
                Ban Author
              </CustomButton>
            ) : null}

            <CustomButton variant="filled" onClick={settleReports}>
              Settle Reports
            </CustomButton>
          </Buttons>

          <List>
            {data.reports.map((item) => (
              <ReportBox item={item} />
            ))}
          </List>
        </Main>
      </Container>
    </WithHeader>
  );

  function banItem() {
    serverLine.post("/ban", {
      subjectID: urlQuery.get("subjectID"),
      subjectType: urlQuery.get("subjectType"),
      action: "BAN",
    });

    popupAlert("Item has been banned");
  }

  function banAuthor() {
    serverLine.post("/ban", {
      subjectID: data.reportItem.authorUserID,
      subjectType: "USER",
      action: "BAN",
    });

    popupAlert("Author has been banned");
  }

  function settleReports() {
    serverLine.post("/settle-reports", {
      subjectID: urlQuery.get("subjectID"),
      subjectType: urlQuery.get("subjectType"),
    });

    popupAlert("Reports have been settled");
  }
}
