import styled from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Link({ href, children }) {
  return <Div onClick={goTo}>{children}</Div>;

  function goTo() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    goTo(href)();
  }
}
