export default function getLinkToS3(fileName) {
  let environment = "production";

  if (window.location.host.indexOf("localhost") !== -1) {
    environment = "development";
  }

  let useCDN = true;

  let base = "http://s3-storage.upon.one.s3.ap-south-1.amazonaws.com";

  if (useCDN) {
    base = `https://s3-storage.upon.one`;
  }

  base = "https://dfeufrvwhbhf8.cloudfront.net";

  return `${base}/${environment}/skysaid/${fileName}`;
}
