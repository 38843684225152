import styled from "styled-components";
import InternshipProgramCard from "./InternshipProgramCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import CompanyStatCard from "./CompanyStatCard";

import { useSpring, animated } from "react-spring";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 25px;
  }
`;

const Description = styled.div`
  text-align: center;
  color: var(--accentVeryLight);
  opacity: 0.7;
  font-size: 18px;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export default function CompanyStat({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  const props = useSpring({
    from: { number: 0 },
    to: { number: 100000 },
    config: { duration: 5000 }, // Duration in milliseconds
  });

  console.log(
    "vvvvvv",
    props.number.to((n) => n.toFixed(0))
  );

  return (
    <Container ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>OUR TRACK RECORD</Title>
          <Description>
            We have earned the trust of thousands of students because we give
            incredible care in what is taught and how it is taught.
          </Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          <CompanyStatCard
            gradient={"linear-gradient(#000000, #3bc613)"}
            image="green-apple"
            title="100% "
            description="Satisfaction"
          />
          <CompanyStatCard
            gradient={"linear-gradient(#000000, #868686)"}
            image="yin-yang"
            title={"1000+"}
            description="Learners"
          />
          <CompanyStatCard
            gradient={"linear-gradient(rgb(0, 0, 0), rgb(45 95 227))"}
            image="shield"
            title="20+"
            description="Experts"
          />
          {/* <CompanyStatCard
            gradient={"linear-gradient(#000000, #c7614c)"}
            image="ok"
            title="40000+"
            description="Stores"
          /> */}
        </List>
      </motion.div>
    </Container>
  );
}
