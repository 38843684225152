import { useContext, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import styled from "styled-components";
import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";

import { AiOutlineSend } from "react-icons/ai";

import { MoonLoader } from "react-spinners";
import goTo from "../../controllers/goTo";
import getHowOldInString from "../../controllers/utils/getHowOldInString";
import limitStringLength from "../../controllers/limitStringLength";
import RenderDatingImages from "./RenderDatingImages";
import CustomButton from "../helperComponents/CustomButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReportsDataBox from "../util/ReportsDataBox";
import handleSendingIfBlocked from "../../controllers/utils/handleSendingIfBlocked";
import FeedItem from "./FeedItem";
import LoadingSection from "../helperComponents/LoadingSection";
import BarLoader from "../helperComponents/BarLoader";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  /* border-bottom: 1px solid var(--translucentHard); */
  gap: 15px;
  background: var(--glassGradientHard);
  width: 100%;
  height: 90px;
  justify-content: center;
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  border: 1px solid var(--foreground);
  backdrop-filter: contrast(1) brightness(1.05);
`;

const MessageSpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 40px;
  padding-top: 0;
  gap: 10px;
  width: 100%;
`;

const MessageSpace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--translucentHard);
  /* background-color: var(--translucentLight); */
  border-radius: 15px;
`;

const WriteMessage = styled.input`
  height: 100%;
  resize: none;
  width: 100%;
  border: none;

  padding: 0;
  padding-left: 20px;
  color: var(--color);
  background-color: transparent;
  font-size: 16px;
`;

const ChatBubble = styled.div`
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 80px);
  background: #fff;
  position: relative;
  padding: 10px;
  border-radius: 7px 7px 7px 7px;
  width: 100%;
  box-shadow: 1px 1px 7px 0px #5893bc7d;

  &:after {
    content: "";
    position: absolute;
    top: 5px;
    background: #fff0;
    left: -18px;
    /* height: 10px; */
    /* width: 30px; */
    /* border-radius: 0 0 0 100px; */
    border: 10px;
    border-left: 10px solid #ff000000;
    border-bottom: 10px solid #b08e8e00;
    border-right: 12px solid #fffffffa;
    border-top: 10px solid #741c1c00;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
`;

const Sent = styled.div`
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background: var(--glassGradientHard);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-shadow: var(--lightShadow);
  border: 1px solid var(--foreground);
`;

export default function DirectMessageBox({ item }) {
  const { setForm, loggedInUserID, popupAlert } = useContext(Context);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);
  const [sent, setSent] = useState(false);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (isDeleted) return null;

  let content = (
    <MessageSpaceContainer>
      <MessageSpace>
        <WriteMessage
          placeholder={`Message ${item.username} `}
          onChange={updateMessage}
          value={message}
        />
      </MessageSpace>

      <CustomButton
        style={{ borderRadius: "15px", padding: "0 15px" }}
        onClick={sendMessage()}
        icon={<AiOutlineSend />}
      >
        Send
      </CustomButton>

      <CustomButton
        style={{ borderRadius: "15px", padding: "0 15px" }}
        onClick={sendMessage(true)}
        icon={<AiOutlineSend />}
      >
        Anonymously
      </CustomButton>
    </MessageSpaceContainer>
  );

  if (sending) content = <BarLoader />;

  return <Main>{content}</Main>;

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  function sendMessage(isAnonymous) {
    return async () => {
      let theMessage = message;
      if (theMessage) theMessage = theMessage.trim();

      if (!theMessage) return;

      setSending(true);

      try {
        let data = await serverLine.post("/initial-message", {
          message: { text: theMessage },
          groupType: isAnonymous ? "ANONYMOUS_DIRECT_CHAT" : "DIRECT_CHAT",
          receiverUserID: item._id,
        });

        if (data.chatGroup) {
          if (handleSendingIfBlocked({ data, popupAlert })) {
            setSending(false);
            return;
          }

          let theGroup = data.chatGroup;

          let content = { text: theMessage };

          window.chatSocket.emit("message", {
            content,
            groupData: theGroup,
            receiverUserID: data.receiverUserID,
          });
        }

        setSent(true);
        popupAlert("Message Sent");
        setMessage("");

        setSending(false);
      } catch (e) {
        setSending(false);
        popupAlert(e.message);
      }
    };
  }
}
