import styled from "styled-components";

import { useContext, useState } from "react";

import LoadingSection from "../helperComponents/LoadingSection.js";
import { serverLine } from "../../controllers/serverLine.js";
import CustomButton from "../helperComponents/CustomButton.js";
import CustomMasonry from "../helperComponents/CustomMasonry.js";

import Context from "../../Context.js";
import Divider from "../util/Divider.js";
import FeedItem from "../cardForFeed/FeedItem.js";
import CustomToggle from "../helperComponents/CustomToggle.js";
import FeedItemMini from "../cardForFeed/FeedItemMini.js";

const Container = styled.div``;
const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  border-bottom: 1px solid var(--translucent);
`;

const Name = styled.div`
  font-size: 25px;
  font-weight: 900;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
`;

const Link = styled.div``;

const FollowButton = styled.div`
  border: 1px solid var(--translucentHard);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
`;

const MainContent = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const HeroTitle = styled.div`
  font-size: 35px;
  font-weight: 900;
`;

const HeroDescription = styled.div`
  font-size: 18px;
  margin-top: -15px;
  width: 600px;
  opacity: 0.7;
  line-height: 30px;
`;

const HeroRight = styled.div``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: var(--contentWidth);
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const SectionTitle = styled.div`
  font-size: 22px;
  opacity: 0.5;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;
const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const RoundButton = styled(Button)`
  padding: 0;
  border: none;
`;
const ButtonText = styled.div``;
const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`;

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const NothingToShow = styled.div``;

const NoMasonry = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Message = styled.div``;

const TopPart = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function ProfileSection({ data, disableMasonry, userData }) {
  const { loggedInUser } = useContext(Context);

  const [type, setType] = useState("GENERAL");

  let options = [{ value: "GENERAL", label: "General" }];

  let visitIsProfileOwner = false;

  if (loggedInUser) {
    if (loggedInUser._id == userData._id) {
      visitIsProfileOwner = true;
      options = [
        ...options,
        { value: "ANONYMOUS", label: "Anonymous" },
        { value: "DATING", label: "Dating" },
      ];
    }
  }

  let list = data[type];

  let colsOnMobile = 1;

  let content = <NothingToShow> Nothing </NothingToShow>;

  let toRender = [];

  if (list) {
    if (list.length) {
      toRender = list.map((item) => (
        <FeedItemMini key={item._id} item={item} />
      ));

      let theList = (
        <CustomMasonry colsOnMobile={colsOnMobile} maxCol={2} list={toRender} />
      );

      if (disableMasonry) theList = <NoMasonry>{toRender}</NoMasonry>;

      content = theList;
    }
  }

  return (
    <Section>
      {visitIsProfileOwner ? (
        <TopPart>
          <CustomToggle
            toggleStyle={{ width: "100%" }}
            options={options}
            value={type}
            onChange={setType}
          />
          <Message>
            Note: Only you can see the anonymous and dating posts on your
            profile, it won't be visible to anyone else.
          </Message>
        </TopPart>
      ) : null}

      {content}
    </Section>
  );
}
