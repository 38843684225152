import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import WhyChooseUsText from "./WhyChooseUsText";
import WhyChooseUsText2 from "./WhyChooseUsText2";
import Context from "../../../Context";

const Canvas = styled.canvas`
  width: 100vw;
  height: 100vh;
`;

const Container = styled.div`
  position: relative;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

let maxFrames = 100;

const BuildingImage = styled.img`
  width: 100%;
  height: auto;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default function WhyChooseUs2({ containerRef }) {
  const { isMobile } = useContext(Context);
  const targetRef = useRef(null);

  const textAnimation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.8 1"],
  });

  const textHozAnimation = useTransform(
    textAnimation.scrollYProgress,
    [0, 1],
    [-300, 0]
  );

  if (isMobile) {
    return <WhyChooseUsText />;
  }

  return (
    <Container
      ref={targetRef}
      style={{
        height: "6000px",
        backgroundColor: "black",
        display: "flex",
      }}
    >
      <Sticky>
        <ImageContainer>
          <BuildingImage src={"/warrior-sharp.png"} />
        </ImageContainer>

        <motion.div
          style={{
            scale: textAnimation.scrollYProgress,
            x: textHozAnimation,
            position: "absolute",
            top: "100px",
            zIndex: "555",
            left: "150px",
            width: "100%",
          }}
        >
          <WhyChooseUsText />
        </motion.div>
      </Sticky>
    </Container>
  );
}

function addZeros(str) {
  str = str.toString();

  if (str.length == 1) return `000${str}`;
  if (str.length == 2) return `00${str}`;
  if (str.length == 3) return `0${str}`;
  if (str.length == 4) return str;
}
