import { styled } from "styled-components";

import { BiCheckDouble, BiCheck } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
const Container = styled.div`
  font-size: 18px;
  opacity: 0.5;
`;

export default function TickStatus({ message, oppositeMemberLastSeenAt }) {
  let theSymbol = null;

  if (message.isNew) {
    theSymbol = <AiOutlineClockCircle />;

    if (message.isSent) {
      theSymbol = <BiCheck />;
    }

    if (message.isSeen) {
      theSymbol = <BiCheckDouble />;
    }
  } else {
    theSymbol = <BiCheck />;

    if (hasSeen()) {
      theSymbol = <BiCheckDouble />;
    }
  }

  return <Container>{theSymbol}</Container>;

  function hasSeen() {
    let createdAt = new Date(message.createdAt);
    oppositeMemberLastSeenAt = new Date(oppositeMemberLastSeenAt);

    return oppositeMemberLastSeenAt.getTime() > createdAt.getTime();
  }
}
