import { useContext, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import styled from "styled-components";
import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";

import { AiOutlineSend } from "react-icons/ai";

import { MoonLoader } from "react-spinners";
import goTo from "../../controllers/goTo";
import getHowOldInString from "../../controllers/utils/getHowOldInString";
import getOppositeMemberID from "../../controllers/getOppositeMemberID";
import limitStringLength from "../../controllers/limitStringLength";
import RenderDatingImages from "./RenderDatingImages";
import CustomButton from "../helperComponents/CustomButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReportsDataBox from "../util/ReportsDataBox";
import handleSendingIfBlocked from "../../controllers/utils/handleSendingIfBlocked";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  border-radius: 10px;
  background: var(--glassGradientHard);
  border: 1px solid var(--foreground);
  /* border: 1px solid var(--translucentHard); */
  overflow: hidden;
  box-shadow: 0px 1px 9px #00000026;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 25px;
  /* border-bottom: 1px solid var(--translucentHard); */
  gap: 10px;
`;

const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  object-fit: cover;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 700;
  background-color: var(--foreground);
  box-shadow: var(--lightShadow);
  border-radius: 0 15px 15px 15px;
  padding: 15px 20px;
`;

const Content2 = styled.div`
  font-size: 15px;
  padding: 15px 25px;
  opacity: 0.6;
  background-color: transparent;
  border-bottom: 1px solid var(--translucentHard);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MessageSpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  height: 65px;
  padding-top: 0;
  gap: 10px;
`;

const MessageSpace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--translucentHard);
  /* background-color: var(--translucentLight); */
  border-radius: 15px;
`;

const WriteMessage = styled.input`
  height: 100%;
  resize: none;
  width: 100%;
  border: none;

  padding: 0;
  padding-left: 20px;
  color: var(--color);
  background-color: transparent;
  font-size: 16px;
`;

const B = styled.b``;

const Name = styled.span`
  text-transform: capitalize;
  font-weight: 600;
`;

const PostButton = styled.button`
  height: 100%;
  border: none;
  border-left: 1px solid var(--translucentHard);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  gap: 10px;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  color: var(--color);

  &:hover {
    background-color: var(--foreground);
  }
`;

const PostButtonIcon = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

export default function FeedItem({ item, showReportsData }) {
  const { popupAlert, loggedInUserID, setForm } = useContext(Context);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (!item.author) {
    item.author = { name: "Anonymous", bio: "", profileImage: null };
  }

  if (isDeleted) return null;

  return (
    <Container>
      {item.type == "DATING" ? (
        <RenderDatingImages images={item.author.images} />
      ) : null}
      <Content2 onClick={gotoUserProfile()}>
        <Name> {item.author.name}</Name> . {getHowOldInString(item.createdAt)}
        {item.author.tagline
          ? " . " + limitStringLength(item.author.tagline, 40)
          : null}
      </Content2>
      <Main>
        <ProfileImage src={getImageURL(item.author.profileImage, true)} />
        <Content>{item.title}</Content>
      </Main>

      <MessageSpaceContainer>
        <CustomButton
          style={{ width: "35px", borderRadius: "15px", padding: 0 }}
          onClick={showContentMenu}
          icon={<BsThreeDotsVertical />}
        />

        <MessageSpace>
          <WriteMessage
            placeholder={"Type Message Here"}
            onChange={updateMessage}
            value={message}
          />

          <PostButton onClick={sendMessage}>
            <PostButtonIcon>
              {sending ? <MoonLoader size={20} /> : <AiOutlineSend />}
            </PostButtonIcon>
          </PostButton>
        </MessageSpace>
      </MessageSpaceContainer>

      {showReportsData ? (
        <ReportsDataBox item={item} subjectType={"CONTENT"} />
      ) : null}
    </Container>
  );

  function showContentMenu() {
    let options = [];

    if (loggedInUserID == item.authorUserID) {
      options = [
        { name: "Edit Content", onClick: goTo(`/edit-content/${item._id}`) },
        { name: "Delete Content", onClick: deleteContent },
      ];
    } else {
      options = [
        {
          name: "Report Post",
          onClick: goTo(
            `/create-report/?subjectType=CONTENT&subjectID=${item._id}`
          ),
        },
      ];
    }

    setForm({ options: options });
  }

  function deleteContent() {
    let options = [
      { name: "Yes! Delete Post", onClick: doIt },
      {
        name: "No",
        onClick: () => {
          setForm(null);
        },
      },
    ];

    setForm({ options: options });

    function doIt() {
      popupAlert("Post Deleted!");
      setIsDeleted(true);
      setForm(null);
      serverLine.delete(`/content/?contentID=${item._id}`);
    }
  }

  function gotoUserProfile() {
    if (item.type == "ANONYMOUS") return null;
    return goTo(`/${item.author.username}`);
  }

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  async function sendMessage() {
    let theMessage = message;
    if (theMessage) theMessage = theMessage.trim();

    if (!theMessage) return;

    setSending(true);

    try {
      let data = await serverLine.post("/initial-message", {
        message: { text: theMessage },
        groupType: "CONTENT_CHAT",
        contentID: item._id,
      });

      if (data.chatGroup) {
        if (handleSendingIfBlocked({ data, popupAlert })) {
          setSending(false);
          return;
        }

        let theGroup = data.chatGroup;
        window.chatSocket.emit("message", {
          content: { text: theMessage },
          groupData: theGroup,
          receiverUserID: data.receiverUserID,
        });
      }

      popupAlert("Message Sent");
      setMessage("");

      setSending(false);
    } catch (e) {
      setSending(false);
      popupAlert(e.message);
    }
  }
}
