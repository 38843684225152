import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import { serverLine } from "../../../controllers/serverLine";
import { useParams } from "react-router-dom";

import goTo from "../../../controllers/goTo";
import { styled } from "styled-components";

import Context from "../../../Context";
import reachChatPanelEnd from "../../../controllers/reachChatPanelEnd";
import { io } from "socket.io-client";
import getOppositeMemberID from "../../../controllers/getOppositeMemberID";

import getSubPath from "../../../controllers/getSubPath";
import ChatGroupBox from "../../chat/ChatGroupBox";
import ChatPanel from "../../chat/ChatPanel";
import PopupLogin from "../../auth/PopupLogin";
import LoadingSection from "../../helperComponents/LoadingSection";
import getServerURL from "../../../controllers/getServerURL";
import InfoBox from "../../util/InfoBox";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  width: calc(100% - var(--mainPadding));
  margin-right: var(--mainPadding);
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 0;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    margin: 0;
    overflow: visible;
    box-shadow: var(--hardShadow);
  }
`;
const LeftPanel = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-right: 1px solid var(--translucentHard);
  /* border-left: 1px solid var(--translucentHard); */

  @media (max-width: 900px) {
    border: none;
    ${({ groupID }) => {
      if (groupID) return `display:none;`;
    }}
  }
`;

const Info = styled.div`
  width: 100%;
  padding: 20px;
`;

export default function ChatPage() {
  const {
    groups,
    friendsLastSeenAt,
    oppositeMemberLastTypedAt,
    selectedGroupData,
    setSelectedGroupData,
    setOppositeMemberLastTypedAt,
    setGroups,
    chatLoading,
    loggedInUser,
  } = useContext(Context);

  const { loggedInUserID } = useContext(Context);
  window.loggedInUserID = loggedInUserID;

  let groupID = getSubPath(1);

  if (!loggedInUserID) return <PopupLogin />;
  if (chatLoading) return <LoadingSection />;

  let groupBoxes = groups.map((item) => (
    <ChatGroupBox
      highlight={groupID == item.groupID}
      friendsLastSeenAt={friendsLastSeenAt}
      item={item}
      onClick={selectNewGroup}
    />
  ));

  if (loggedInUser) {
    if (loggedInUser.banned)
      return (
        <WithHeader>
          <InfoBox>
            You can't access this page because you have been banned.
          </InfoBox>
        </WithHeader>
      );
  }

  return (
    <WithHeader>
      <Container>
        <LeftPanel groupID={groupID}>
          {groupBoxes.length ? groupBoxes : <Info> 0 Contacts Found</Info>}
        </LeftPanel>
        <ChatPanel
          loadNewMessages={loadNewMessages}
          groupID={groupID}
          oppositeMemberLastTypedAt={oppositeMemberLastTypedAt}
          friendsLastSeenAt={friendsLastSeenAt}
          markAsSent={markAsSent}
          addMessage={addMessage}
          selectedGroupData={selectedGroupData}
        />
      </Container>
    </WithHeader>
  );

  function markAsSent(pseudoID) {
    let newSelectedGrp = { ...window.selectedGroupData };

    for (let item of newSelectedGrp.messages) {
      if (item.pseudoID == pseudoID) {
        item.isSent = true;
      }
    }

    console.log(newSelectedGrp);
    window.setSelectedGroupData(newSelectedGrp);
  }

  function sendAllSeenMessage({ groupData, receiverUserID }) {
    window.chatSocket.emit("seen", {
      groupData,
      receiverUserID,
    });
  }

  function addMessage(newMessage) {
    let newSelectedGrp = { ...window.selectedGroupData };
    newSelectedGrp.messages.push(newMessage);
    setSelectedGroupData(newSelectedGrp);

    if (window.selectedGroupData) {
      if (window.selectedGroupData.youHaveBeenBlocked) return;
    }

    window.chatSocket.emit("message", {
      content: newMessage.message,
      groupData: newSelectedGrp.group,
      receiverUserID: newSelectedGrp.oppositeMemberID,
    });
  }

  function selectNewGroup(groupData) {
    setOppositeMemberLastTypedAt(null);

    let gotoOptions = null;
    if (groupID) {
      gotoOptions = { isReplace: true };
    }
    goTo("/chat/" + groupData.groupID, gotoOptions)();
    setSelectedGroupData("LOADING");

    loadNewMessages(groupData.groupID).then((newSelectedGrp) => {
      sendAllSeenMessage({
        groupData: newSelectedGrp.group,
        receiverUserID: newSelectedGrp.oppositeMemberID,
      });

      reachChatPanelEnd();
      markMessagesAsRead(groupData.groupID);
    });
  }

  function markMessagesAsRead(groupID) {
    let initialGroups = [...groups];

    for (let item of initialGroups) {
      if (item.groupID == groupID) {
        item.notificationData[item.myID].unreadMessages = 0;
      }
    }

    setGroups(initialGroups);
  }

  async function loadNewMessages(newGroupID) {
    setSelectedGroupData("LOADING");
    let newData = await serverLine.get("/messages/?groupID=" + newGroupID);

    setSelectedGroupData(newData);

    return newData;
  }
}
