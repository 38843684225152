import { styled } from "styled-components";
import { useContext } from "react";

import Context from "../../Context";

import LoadingSection from "../helperComponents/LoadingSection";
import FeedItem from "../cardForFeed/FeedItem";
import ContentFeed from "../feed/ContentFeed";
import capitalizeFirstLetter from "../../controllers/capitalizeFirstLetter";
import FeedItemMini from "../cardForFeed/FeedItemMini";

const Info = styled.div`
  margin-top: 50px;
  opacity: 0.5;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
`;

const ContentPart = styled.div`
  display: flex;
  flex-direction: column;
  width: 38vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Notice = styled.div`
  width: 38vw;
  padding: 20px;
  border-radius: 10px;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  margin-top: -50px;
  opacity: 0.6;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function RenderContent({ postData }) {
  const { loggedInUserID } = useContext(Context);

  if (!postData) return null;

  if (postData.loading) return <LoadingSection />;

  if (postData.archived) return <Info>Content has been deleted.</Info>;

  let content = <FeedItemMini item={postData} />;

  if (postData.banned) {
    if (postData.authorUserID === loggedInUserID) {
      content = (
        <>
          <Info>Your content has been banned.</Info>
          <br />
          <br /> <br /> <br />
          {content}
        </>
      );
    } else {
      content = (
        <>
          <Info>{postData.title}</Info>
          <Info>This content has been banned.</Info>
        </>
      );
    }
  }

  let peopleReached = postData.peopleReached ? postData.peopleReached : 0;

  return (
    <Main>
      <ContentPart>{content}</ContentPart>
      <Notice>
        {" "}
        Once the post receives messages from 10 different people, the post will
        be removed from the feed. Limit Reached: {peopleReached}/10
      </Notice>
      {/* <RegisterView subjectType={"POST"} subjectID={postData._id} /> */}
      <ContentFeed
        disabledInfiniteScroll={true}
        type={postData.type}
        categoryType={postData.category}
        title={capitalizeFirstLetter(postData.category.toLowerCase())}
      />
    </Main>
  );
}
