export default function parseScrollData(setScrollData) {
  return ({ target }) => {
    let data = {
      scrollHeight: target.scrollHeight,
      scrollTop: target.scrollTop,
      clientHeight: target.clientHeight,
    };
    setScrollData(data);
  };
}
