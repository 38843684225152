import { useEffect } from "react";
import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";
import LoadingSection from "./LoadingSection";

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export default function InfiniteScroll({
  loading,
  load,
  scrollData,
  reachedTheEnd,
  children,
  distance = 300,
}) {
  useEffect(() => {
    if (scrollData && !reachedTheEnd) {
      let { scrollHeight, scrollTop, clientHeight } = scrollData;
      let distanceScrolled = scrollTop + clientHeight;
      console.log(scrollHeight, distanceScrolled);
      if (scrollHeight - distanceScrolled < distance) {
        if (!loading) {
          console.log("reached bottom, loading");
          load();
        }
      }
    }
  }, [scrollData, loading]);

  return (
    <List>
      {children}
      <br />
      {reachedTheEnd && !loading ? (
        <CustomButton disabled={true} variant="minimal">
          End Reached
        </CustomButton>
      ) : null}
      {loading ? <LoadingSection /> : null}
    </List>
  );
}
