import pathVsComp from "../../data/pathVsComp";

export default function getPopupType(pathName) {
  let pathSplit = pathName.split("/");

  let firstSplit = pathSplit[1];
  if (!firstSplit) return false;
  if (firstSplit == "content") return "CONTENT";
  if (isProfile("/" + firstSplit)) return "PROFILE";

  return false;
}

function isProfile(path) {
  if (pathVsComp[path]) return false;

  return true;
}
