import CustomButton from "../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../controllers/goTo";
import { serverLine } from "../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";

import CustomMasonry from "../helperComponents/CustomMasonry";

import FeedSection from "./FeedSection";
import InfiniteScroll from "../helperComponents/InfiniteScroll";
import FeedItem from "../cardForFeed/FeedItem";

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import LoadingSection from "../helperComponents/LoadingSection";
import FeedItemMini from "../cardForFeed/FeedItemMini";
import Context from "../../Context";
import FeedItemMobile from "../cardForFeed/FeedItemMobile";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
`;
const Message = styled.div`
  font-size: 21px;
  /* background-color: var(--translucent); */
  padding: 0;
  border-radius: 10px;
  font-weight: 300;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  display: flex;
  opacity: 0.7;
  gap: 10px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
`;
const TitleText = styled.div``;

const Top = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
`;
const Left = styled.div`
  font-size: 31px;
  font-weight: 100;
`;

export default function ContentFeed({
  type,
  categoryType,
  workType,
  title,
  mode = "COMPACT",
  scrollData,
  maxCol = 2,
  disabledInfiniteScroll,
  loadSecondIndexAsWell,
}) {
  const { isMobile } = useContext(Context);
  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  useEffect(() => {
    setOffset(0);
    setList([]);
    doLoading({
      freshLoad: true,
      newType: type,
      newCategoryType: categoryType,
    });
  }, [type, categoryType]);

  let content = null;

  let Comp = isMobile ? FeedItemMobile : FeedItemMini;

  if (loading) {
    //the infinite scroll loading will do the job
    // content = <LoadingSection />;
  } else {
    let items = list.map((item) => <Comp key={item._id} item={item} />);

    let colsOnMobile = 1;

    content = (
      <CustomMasonry colsOnMobile={colsOnMobile} maxCol={maxCol} list={items} />
    );

    if (!list.length) return null;

    if (!list.length)
      content = (
        <CustomButton variant="minimal" disabled={true}>
          Nothing Found
        </CustomButton>
      );
  }

  let core = (
    <>
      {content}
      {showSeeMore()}
    </>
  );

  let toRender = core;

  if (!disabledInfiniteScroll)
    toRender = (
      <InfiniteScroll
        loading={loading}
        load={doLoading}
        scrollData={scrollData}
        reachedTheEnd={reachedTheEnd}
      >
        {core}
      </InfiniteScroll>
    );

  return <FeedSection title={title}>{toRender}</FeedSection>;

  function showSeeMore() {
    if (mode !== "COMPACT") return null;

    if (!list.length) return null;

    if (list.length < 15) return null;

    let typeToSend = type == "WORK" ? workType : type;

    return (
      <CustomButton
        style={{ fontSize: "13px", width: "100px", padding: "10px" }}
        variant="outlined"
        onClick={goTo(`/feed/?type=${typeToSend}&subType=${categoryType}`)}
      >
        See More
      </CustomButton>
    );
  }

  async function loadData({ offset, categoryType, type }) {
    let loadedData = await serverLine.get(
      `/feed/?type=${type}&subType=${categoryType}&offset=${offset}`
    );

    return loadedData;
  }

  async function doLoading({ freshLoad, newType, newCategoryType }) {
    setLoading(true);
    if (!newType) newType = type;
    if (!newCategoryType) newCategoryType = categoryType;

    let theOffset = offset;

    if (freshLoad) theOffset = 0;

    let loadedData = await loadData({
      offset: theOffset,
      type: newType,
      categoryType: newCategoryType,
    });

    let newOffset = theOffset + 1;
    let newList = [];

    if (!freshLoad) newList = [...list];

    if (!loadedData.length) {
      if (theOffset !== 0) setReachedTheEnd(true);
    } else {
      setOffset(newOffset);
      newList = [...loadedData];
      setList(newList);
    }

    setLoading(false);

    if (loadSecondIndexAsWell) {
      if (newOffset == 1) {
        let newData = await loadData({
          offset: newOffset,
          categoryType: newCategoryType,
          type: newType,
        });
        newList = [...newList, ...newData];
        setList(newList);
        setOffset(newOffset + 1);
      }
    }
  }
}
