import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import ContentFeed from "./ContentFeed";
import Context from "../../Context";
import LoadingSection from "../helperComponents/LoadingSection";
import contentCategories from "../../data/contentCategories";
import CustomToggle from "../helperComponents/CustomToggle";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  width: 100%;

  padding: 0;
  padding-top: 20px;
  /* overflow: hidden; */ //It clips the shadow of the content
  /* overflow-y: scroll; */

  @media (max-width: 900px) {
    padding: 0;
    gap: 35px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  /* width: 38vw; */
`;

let workOptions = [
  { label: "Show All", value: "WORK" },
  { label: "Show Recruiters", value: "WORK_SEEKER" },
  { label: "Show Talent", value: "RECRUITER" },
];

export default function MainFeed({ type }) {
  const { loggedInUser, isMobile } = useContext(Context);
  const [categoryType, setCategoryType] = useState("LATEST");
  const [workType, setWorkType] = useState("WORK");

  useEffect(() => {
    setCategoryType("LATEST");
  }, [type]);

  if (!loggedInUser) return <LoadingSection />;

  let options = [{ label: "Latest", value: "LATEST" }];

  if (type == "GENERAL")
    options.push(
      loggedInUser.gender == "MALE"
        ? { label: "From Gals", value: "FROM_GALS" }
        : { label: "From Guys", value: "FROM_GUYS" }
    );

  options = [...options, ...contentCategories[type]];

  let toggleStyle = {
    flexWrap: "nowrap",
    // display: "grid",
    // background: "transparent",
    // border: "none",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  };

  // toggleStyle = null;

  let optionRow = (
    <Row>
      <CustomToggle
        toggleStyle={type == "GENERAL" ? toggleStyle : null}
        value={categoryType}
        onChange={setCategoryType}
        options={options}
      />

      {type == "WORK" ? (
        <CustomToggle
          value={workType}
          onChange={setWorkType}
          options={workOptions}
        />
      ) : null}
    </Row>
  );

  if (isMobile) optionRow = null;

  return (
    <Container>
      {optionRow}
      
      <ContentFeed
        workType={workType}
        type={type}
        categoryType={categoryType}
      />

      <br />
      <br />
      <br />
    </Container>
  );
}
