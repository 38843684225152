function getServerURL() {
  let base = "https://skysaid.com";

  if (window.location.origin.indexOf("skysaid.com") == -1) {
    if (process.env.REACT_APP_SERVER) base = process.env.REACT_APP_SERVER;
  }

  // console.log(base);
  return base;
}

export default getServerURL;
