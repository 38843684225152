import { useContext, useState } from "react";
import { styled } from "styled-components";
import Context from "../../Context.js";
import MaterialInput from "./MaterialInput.js";
import { AiOutlineStar } from "react-icons/ai";

const Container = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  background: var(--glassGradient);
  font-weight: 500;
  border: 1px solid var(--foreground);
  border-radius: 10px;

  &:hover {
    background: var(--foreground);
  }

  ${({ isActive }) => {
    if (isActive)
      return `
           background: var(--foreground);
    `;
  }}
`;

const ItemText = styled.div``;

const ImportantSymbol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PopupSelect({ value, options, onChange }) {
  const [query, setQuery] = useState("");
  const { setForm } = useContext(Context);

  return (
    <Container>
      <MaterialInput
        autoFocus={true}
        onChange={({ target }) => {
          setQuery(target.value);
        }}
        label="Type here to search"
      />
      <List>{renderList()}</List>
    </Container>
  );

  function renderList() {
    let items = [];

    for (let item of options) {
      if (query) {
        let toMatch = item.label.toLowerCase();
        if (toMatch.indexOf(query.toLowerCase()) === -1) {
          continue;
        }
      }
      items.push(
        <Item
          key={item.value}
          isActive={value == item.value}
          onClick={() => {
            setForm(null);
            onChange(item.value);
          }}
        >
          <ItemText>{item.label}</ItemText>

          {item.isImportant ? (
            <ImportantSymbol>
              <AiOutlineStar />
            </ImportantSymbol>
          ) : null}
        </Item>
      );
    }

    return items;
  }
}
