import styled from "styled-components";

const OnlineBubble = styled.div`
  position: absolute;
  right: -2px;
  bottom: -2px;
  border-radius: 500px;
  padding: 0;
  width: 15px;
  height: 15px;
  font-size: 0;
  background-color: var(--accentColor);
  color: var(--bgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--foreground);
`;

export default OnlineBubble;
