import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import WithHeader from "../WithHeader";
import Context from "../../../Context";
import CustomToggle from "../../helperComponents/CustomToggle";

import parseScrollData from "../../../controllers/parseScrollData";
import NotificationsCore from "../../notifications/NotificationsCore";
import LoadingSection from "../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  gap: 25px;
  align-items: center;
`;

const Title = styled.div`
  text-align: center;
`;

const Main = styled.div`
  width: 38vw;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function Notifications() {
  let { loggedInUser } = useContext(Context);
  const [scrollData, setScrollData] = useState(null);

  if (!loggedInUser) return <LoadingSection />;

  return (
    <Container onScroll={parseScrollData(setScrollData)}>
      <Main>
        <Title>Alerts</Title>
        <NotificationsCore scrollData={scrollData} />
        <br /> <br /> <br />
      </Main>
    </Container>
  );
}
