import { useContext } from "react";
import { styled } from "styled-components";
import Context from "../../Context";
import PopupSelect from "./PopupSelect";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
const Name = styled.div`
  font-size: 23px;
  /* font-weight: 800; */
`;
const Btn = styled.div`
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 5px;

  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  background: var(--glassGradient) !important;
  border: 1px solid var(--foreground);
  box-shadow: var(--lightShadow);
  opacity: 0.9;

  ${({ variant }) => {
    if (variant === "fluid")
      return `
      border-radius:10px;

    `;
  }}

  ${({ isActive }) => {
    if (isActive)
      return `
        opacity:1;
        background: var(--foreground) !important;
        color: var(--color) !important;
       
    `;

    return `
    &:hover {
      opacity: 1;
      background: var(--foreground) !important;
    }
    
    `;
  }}

${({ isActive, disableInActive }) => {
    if (!isActive && disableInActive)
      return `
        opacity:0.5;
       
    `;
  }}
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;

  /* background: var(--translucent); */

  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  padding: 7px;

  /* overflow: hidden; */
  /* width: 100%; */
  gap: 5px;
  flex-wrap: wrap;

  ${({ variant }) => {
    if (variant === "fluid")
      return `
      overflow: unset;
      flex-wrap:wrap;
      gap:10px;
    `;
  }}
`;

const ButtonLabel = styled.div``;
export default function CustomToggle({
  variant,
  name,
  value,
  onChange,
  options,
  style,
  toggleStyle,
  btnStyle,
  disableInActive,
  btnPrefix,
}) {
  const { setForm } = useContext(Context);

  let optionsToProcess = options;

  if (!optionsToProcess) {
    if (!value) value = false;

    optionsToProcess = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }

  let limit = 9;

  if (optionsToProcess.length > limit) {
    optionsToProcess = options.slice(0, limit);
    optionsToProcess.push({ label: "More", onClick: showMoreOptionsInPopup });
  }

  let PrefixComp = btnPrefix;

  let tgl = (
    <Toggle variant={variant} style={toggleStyle}>
      {optionsToProcess.map((item) => (
        <Btn
          disableInActive={disableInActive}
          variant={variant}
          style={btnStyle}
          key={item.value}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            } else {
              onChange(item.value);
            }
          }}
          isActive={item.value === value}
        >
          {PrefixComp ? <PrefixComp config={item} /> : null}
          <ButtonLabel>{item.label}</ButtonLabel>
        </Btn>
      ))}
    </Toggle>
  );

  if (!name) return tgl;

  return (
    <Container style={style}>
      {name ? <Name>{name}</Name> : null}

      {tgl}
    </Container>
  );

  function showMoreOptionsInPopup() {
    let leftOptions = options.slice(limit, options.length);

    setForm({
      title: "More Options",
      component: (
        <PopupSelect value={value} onChange={onChange} options={leftOptions} />
      ),
    });
  }
}
