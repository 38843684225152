import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import WhyChooseUsText from "./WhyChooseUsText";

const Canvas = styled.canvas`
  width: 100vw;
  height: 100vh;
`;

const Container = styled.div`
  position: relative;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

let maxFrames = 100;

export default function WhyChooseUs({ containerRef }) {
  const ref = useRef(null);
  const targetRef = useRef(null);

  const warriorAnimation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.2 1", "0.8 1"],
  });

  const textAnimation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.8 1"],
  });

  const textHozAnimation = useTransform(
    textAnimation.scrollYProgress,
    [0, 1],
    [-300, 0]
  );

  const images = useMemo(() => {
    const loadedImages = [];

    for (let i = 1; i <= maxFrames; i++) {
      const img = new Image();
      img.src = `/warrior/${addZeros(i)}.jpg`;
      console.log(img.src);
      loadedImages.push(img);
    }

    return loadedImages;
  }, []);

  const render = useCallback(
    (index) => {
      let context = ref.current?.getContext("2d");
      let theImage = images[index - 1];

      if (context) {
        context.canvas.width = window.innerWidth;
        context.canvas.height = window.innerHeight;
        if (theImage) context.drawImage(theImage, 0, 0);
      }
    },
    [images]
  );

  useEffect(() => {
    let context = ref.current?.getContext("2d");

    if (context) {
      context.canvas.width = window.innerWidth;
      context.canvas.height = window.innerHeight;
    }
  }, [ref]);

  const currentIndex = useTransform(
    warriorAnimation.scrollYProgress,
    [0, 1],
    [1, maxFrames]
  );

  useMotionValueEvent(currentIndex, "change", (latest) => {
    render(Number(latest.toFixed()));
  });

  useEffect(() => {
    render(1);
  }, [render]);

  console.log("aaaaaa", textAnimation.scrollYProgress);

  return (
    <Container
      ref={targetRef}
      style={{
        height: "6000px",
        backgroundColor: "black",
        display: "flex",
      }}
    >
      <Sticky>
        <Canvas ref={ref} />

        <motion.div
          style={{
            scale: textAnimation.scrollYProgress,
            x: textHozAnimation,
            position: "absolute",
            top: "100px",
            zIndex: "555",
            left: "130px",
          }}
        >
          <WhyChooseUsText />
        </motion.div>
      </Sticky>
    </Container>
  );
}

function addZeros(str) {
  str = str.toString();

  if (str.length == 1) return `000${str}`;
  if (str.length == 2) return `00${str}`;
  if (str.length == 3) return `0${str}`;
  if (str.length == 4) return str;
}
