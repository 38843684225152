import { useContext, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import styled from "styled-components";
import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";

import { AiOutlineSend } from "react-icons/ai";

import { MoonLoader } from "react-spinners";
import goTo from "../../controllers/goTo";
import getHowOldInString from "../../controllers/utils/getHowOldInString";
import getOppositeMemberID from "../../controllers/getOppositeMemberID";
import limitStringLength from "../../controllers/limitStringLength";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  border-radius: 10px;
  background: var(--glassGradientHard);
  border: 1px solid var(--foreground);
  /* border: 1px solid var(--translucentHard); */
  overflow: hidden;
  box-shadow: 0px 1px 9px #00000026;
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 25px;
  /* border-bottom: 1px solid var(--translucentHard); */
  gap: 10px;
`;

const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  object-fit: cover;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 700;
  background-color: var(--foreground);
  box-shadow: var(--lightShadow);
  border-radius: 0 15px 15px 15px;
  padding: 15px 20px;
`;

const Content2 = styled.div`
  font-size: 15px;
  padding: 15px 25px;
  opacity: 0.6;
  background-color: transparent;
  border-bottom: 1px solid var(--translucentHard);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MessageSpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  height: 65px;
  padding-top: 0;
`;

const MessageSpace = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--translucentHard);
  /* background-color: var(--translucentLight); */
  border-radius: 20px;
`;

const WriteMessage = styled.input`
  height: 100%;
  resize: none;
  width: 100%;
  border: none;

  padding: 0;
  padding-left: 20px;
  color: var(--color);
  background-color: transparent;
  font-size: 16px;
`;

const B = styled.b``;

const Name = styled.span`
  text-transform: capitalize;
  font-weight: 600;
`;

const PostButton = styled.button`
  height: 100%;
  border: none;
  border-left: 1px solid var(--translucentHard);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  gap: 10px;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  color: var(--color);

  &:hover {
    background-color: var(--translucent);
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 15px;
  padding-bottom: 5px;
  /* background: var(--glassGradient);
border: 1px solid var(--foreground); */
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: var(--hardShadow);

  @media (max-width: 900px) {
    height: 100px;
  }
`;

const BigImage = styled.img`
  width: 100%;
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  height: auto;
`;

const PopupImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 25px;
`;

export default function RenderDatingImages({ images }) {
  const { setForm } = useContext(Context);

  if (!images) return null;

  if (images.length < 3) return null;

  return (
    <Row>
      <Image onClick={imageClicked(0)} src={getImageURL(images[0])} />
      <Image onClick={imageClicked(1)} src={getImageURL(images[1])} />
    </Row>
  );

  function imageClicked(index) {
    return () => {
      let theImage = images[index];

      setForm({
        title: "Image",
        component: (
          <PopupImage>
            <BigImage src={getImageURL(theImage)} />
          </PopupImage>
        ),
      });
    };
  }
}
