import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--averageColor);
  width: 100%;
`;

const Line = styled.div`
  display: inline-block;
  flex: 1;
  height: 2px;
  background-color: var(--translucentHard);

  opacity: 0.2;
`;

const Main = styled.div``;

export default function Divider({ children }) {
  return (
    <Container>
      <Line></Line>
      <Main>{children}</Main>
      <Line></Line>
    </Container>
  );
}
