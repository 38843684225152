export default function getHowOldInString(date) {
  if (!date) return null;

  const now = new Date();
  const inputDate = new Date(date);

  if (isNaN(inputDate)) {
    throw new Error("Invalid date format");
  }

  const diffMs = now - inputDate; // Difference in milliseconds

  const units = {
    minutes: diffMs / (1000 * 60),
    hours: diffMs / (1000 * 60 * 60),
    days: diffMs / (1000 * 60 * 60 * 24),
    months: diffMs / (1000 * 60 * 60 * 24 * 30.44), // Approximate
    years: diffMs / (1000 * 60 * 60 * 24 * 365.25), // Account for leap years
  };

  let result, unit;

  if (units.years >= 1) {
    result = Math.round(units.years);
    unit = "year";
  } else if (units.months >= 1) {
    result = Math.round(units.months);
    unit = "month";
  } else if (units.days >= 1) {
    result = Math.round(units.days);
    unit = "day";
  } else if (units.hours >= 1) {
    result = Math.round(units.hours);
    unit = "hour";
  } else {
    result = Math.round(units.minutes);
    unit = "minute";
  }

  if (result > 1) {
    unit += "s";
  }

  return `${result} ${unit} ago`;
}
