import { useContext } from "react";
import styled from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import { GrGoogle } from "react-icons/gr";
import { MdOutlineChatBubbleOutline } from "react-icons/md";
import {
  RiChat3Fill,
  RiChat3Line,
  RiChatSmile3Fill,
  RiHeart2Line,
  RiHome2Line,
  RiHomeLine,
  RiNotificationLine,
  RiSearch2Fill,
  RiSearch2Line,
  RiUserFill,
  RiUserLine,
} from "react-icons/ri";
import goToProfile from "../../controllers/goToProfile.js";
import Context from "../../Context.js";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import goTo from "../../controllers/goTo.js";
import CustomButton from "../helperComponents/CustomButton.js";
import SemanticButton from "../helperComponents/SemanticButton.js";
import getProfileLink from "../../controllers/getProfileLink.js";
import BrandContainer from "../brand/BrandContainer.js";
import { FiAlertTriangle, FiSearch, FiUmbrella } from "react-icons/fi";
import { BsChat, BsFillUmbrellaFill } from "react-icons/bs";
import getUnreadMessageCount from "../../controllers/utils/getUnreadMessageCount.js";
import {
  PiFeatherFill,
  PiFeatherLight,
  PiMaskHappy,
  PiMaskHappyFill,
} from "react-icons/pi";
import { FaFaceLaugh, FaHandshakeAngle, FaHeart } from "react-icons/fa6";

import { RiUmbrellaFill } from "react-icons/ri";
import { RiUmbrellaLine } from "react-icons/ri";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: var(--glassGradient);
  //this highlights the logo more

  border: 1px solid var(--foreground);
  width: var(--headerWidth);
  height: calc(100vh - var(--mainPadding) - var(--mainPadding));
  font-family: "Raleway";
  overflow: hidden;
  align-items: center;
  padding: 0;
  margin: var(--mainPadding);
`;

const MobileTopNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: transparent;
  //this highlights the logo more
  border: none;
  width: 100%;
  font-family: "Raleway";
  /* overflow: hidden; */
  align-items: center;
  padding: 0 0px;

  height: var(--topHeaderHeight);
`;

const MobileBottomNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  /* backdrop-filter: blur(20px); */
  z-index: 100;
  padding: 13px 15px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: center;
  gap: 15px;
  align-items: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  padding: 13px 0;
  /* padding-left: 80px; */
  font-size: 19px;
  color: rgb(0 110 171);
  width: 80%;
  border-radius: 10px;

  border: 1px solid var(--foreground);

  background: var(--glassGradient);

  ${({ highlight }) => {
    if (highlight)
      return `
        
        background: linear-gradient(111deg, #2896ff, #21baf8);
        border: 1px solid var(--transparent);
        color:  var(--foreground);

        background: #3c94dbd4;
    `;

    return `
    &:hover{
     background: var(--foreground);
    }
       

    `;
  }}

  @media (max-width:900px) {
    background: transparent;
    border: none;
    width: auto;
    padding: 10px 10px;
    opacity: 0.6;

    ${({ highlight }) => {
      if (highlight)
        return `
        opacity: 1;
        width:140px;
        padding: 10px 10px;
        color:  var(--foreground);
        background: #3c94dbd4;
    `;
    }}
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  align-items: center;
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 85px;
  font-size: 17px;
  text-align: left;
  /* background-color: red; */
  justify-content: flex-start;
  opacity: 0.8;
  position: relative;

  @media (max-width: 900px) {
    width: auto;
  }
`;

const Items = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  align-items: center;

  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    width: auto;
    justify-content: space-between;
    /* width: 100%; */
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: -30px;
  /* top: 15px; */
  padding: 0;
  border-radius: 500px;
  height: 25px;
  padding: 0 10px;
  width: auto;
  font-size: 12px;
  background-color: var(--accentColor);
  color: var(--bgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const NotificationBubbleAlert = styled(NotificationBubble)`
  /* color: tomato; */
  background-color: tomato;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  align-items: center;

  width: 100%;

  background: var(--glassGradient);
  border-bottom: 1px solid var(--foreground);

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
  }
`;

export default function Header() {
  const {
    loggedInUser,
    groups,
    loggedInUserID,
    currentRoute,
    notificationData,
    isMobile,
  } = useContext(Context);

  let unreadMessages = calculateUnreadMessages();
  let logoComp = <BrandContainer />;

  if (isMobile) return getMobileHeader();

  if (!loggedInUser)
    return (
      <Container loggedInUser={loggedInUser}>
        <Logo>{logoComp}</Logo>

        <Items>
          <CustomButton
            style={{ width: "90%", borderRadius: "10px" }}
            variant="filled"
            onClick={goToAuthScreen}
            icon={<GrGoogle />}
          >
            Login / Signup
          </CustomButton>
        </Items>
      </Container>
    );

  return (
    <Container loggedInUser={loggedInUser}>
      <Logo>{logoComp}</Logo>
      <Items>
        <ProperButton
          link={"/"}
          icon={<RiUmbrellaLine />}
          iconFilled={<RiUmbrellaFill />}
          highlight={checkHighlight("/")}
        >
          Friendship
        </ProperButton>

        <ProperButton
          link={"/dating"}
          icon={<RiHeart2Line />}
          iconFilled={<FaHeart />}
          highlight={checkHighlight("/dating")}
        >
          Dating
        </ProperButton>

        <ProperButton
          link={"/anonymous"}
          icon={<PiMaskHappy />}
          iconFilled={<PiMaskHappyFill />}
          highlight={checkHighlight("/anonymous")}
        >
          Masked
        </ProperButton>

        <ProperButton
          link={"/chat"}
          icon={<RiChat3Line />}
          iconFilled={<RiChat3Fill />}
          highlight={checkHighlight("/chat")}
        >
          Chat
          {unreadMessages ? (
            <NotificationBubble>{unreadMessages}</NotificationBubble>
          ) : null}
        </ProperButton>

        <ProperButton
          link={`/search`}
          icon={<RiSearch2Line />}
          iconFilled={<RiSearch2Fill />}
          highlight={checkHighlight("/search")}
        >
          Search
        </ProperButton>

        <ProperButton
          highlight={checkHighlight(getProfileLink(loggedInUser.username))}
          link={getProfileLink(loggedInUser.username)}
          onClick={goToProfile(loggedInUser.username)}
          icon={<RiUserLine />}
          iconFilled={<RiUserFill />}
        >
          Profile
          {notificationData.general ? (
            <NotificationBubbleAlert>
              <FiAlertTriangle />
            </NotificationBubbleAlert>
          ) : null}
        </ProperButton>

        <ProperButton
          link={`/create`}
          icon={<PiFeatherLight />}
          iconFilled={<PiFeatherFill />}
          highlight={checkHighlight("/create")}
        >
          Write
        </ProperButton>
      </Items>
    </Container>
  );

  function checkHighlight(route) {
    if (route !== "/") {
      return currentRoute.indexOf(route) !== -1;
    }
    return route == currentRoute;
  }

  function calculateUnreadMessages() {
    let count = 0;

    if (!groups) return 0;
    for (let item of groups) {
      count += getUnreadMessageCount(item);
    }

    return count;
  }

  function getMobileHeader() {
    if (!loggedInUserID)
      return (
        <MobileTopNav>
          <Logo>{logoComp}</Logo>
          <Items>
            <CustomButton
              style={{ borderRadius: "10px" }}
              variant="filled"
              onClick={goToAuthScreen}
              icon={<GrGoogle />}
            >
              Login / Signup
            </CustomButton>
          </Items>
        </MobileTopNav>
      );

    let btnStyles = { borderRadius: "20px", fontSize: "13px", padding: "0" };
    let btnSize = "35px";
    return (
      <>
        <MobileTopNav>
          <CustomButton
            height={btnSize}
            width={btnSize}
            link={`/search`}
            style={btnStyles}
            variant="filled"
            icon={<RiSearch2Line />}
          ></CustomButton>

          <Logo>{logoComp}</Logo>

          <CustomButton
            height={btnSize}
            width={btnSize}
            variant="filled"
            style={btnStyles}
            link={getProfileLink(loggedInUser.username)}
            onClick={goToProfile(loggedInUser.username)}
            icon={<RiUserLine />}
          >
            {notificationData.general ? (
              <NotificationBubbleAlert>
                <FiAlertTriangle />
              </NotificationBubbleAlert>
            ) : null}
          </CustomButton>
        </MobileTopNav>

        <MobileBottomNav>
          <ProperButton
            link={"/"}
            icon={<RiUmbrellaLine />}
            iconFilled={<RiUmbrellaFill />}
            highlight={checkHighlight("/")}
          >
            Friendship
          </ProperButton>

          <ProperButton
            link={"/dating"}
            icon={<RiHeart2Line />}
            iconFilled={<FaHeart />}
            highlight={checkHighlight("/dating")}
          >
            Dating
          </ProperButton>

          <ProperButton
            link={"/anonymous"}
            icon={<PiMaskHappy />}
            iconFilled={<PiMaskHappyFill />}
            highlight={checkHighlight("/anonymous")}
          >
            Masked
          </ProperButton>

          <ProperButton
            link={"/chat"}
            icon={<RiChat3Line />}
            iconFilled={<RiChat3Fill />}
            highlight={checkHighlight("/chat")}
          >
            Chat
            {unreadMessages ? (
              <NotificationBubble>{unreadMessages}</NotificationBubble>
            ) : null}
          </ProperButton>

          <ProperButton
            link={`/create`}
            icon={<PiFeatherLight />}
            iconFilled={<PiFeatherFill />}
            highlight={checkHighlight("/create")}
          >
            Write
          </ProperButton>
        </MobileBottomNav>
      </>
    );
  }
}

function ProperButton({
  link,
  onClick,
  icon,
  iconFilled,
  children,
  highlight,
}) {
  const { isMobile } = useContext(Context);

  if (isMobile && !highlight) {
    children = null;
    icon = iconFilled;
  }

  return (
    <SemanticButton
      style={{ width: isMobile ? "auto" : "100%" }}
      semanticHref={link}
      onClick={onClick ? onClick : goTo(link)}
    >
      <Button highlight={highlight}>
        <ButtonIcon>{highlight ? iconFilled : icon}</ButtonIcon>
        {children ? <ButtonText>{children}</ButtonText> : null}
      </Button>
    </SemanticButton>
  );
}
