import { PiMaskHappyFill } from "react-icons/pi";

export default function getAnonymousIcon(chatGroup) {
  if (chatGroup.contentType == "ANONYMOUS") return <PiMaskHappyFill />;

  if (chatGroup.type == "ANONYMOUS_DIRECT_CHAT") {
    return <PiMaskHappyFill />;
  }

  return false;
}
