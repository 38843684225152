import styled from "styled-components";
import InternshipProgramCard from "./InternshipProgramCard";
import { useScroll, motion } from "framer-motion";
import { useContext, useRef } from "react";
import TestimonialCard from "./TestimonialCard";
import Context from "../../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 30px;
    text-align: unset;
  }
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;

  @media (max-width: 900px) {
    text-align: unset;
  }
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export default function Testimonials({ containerRef }) {
  const { isMobile } = useContext(Context);
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  return (
    <Container ref={targetRef}>
      <motion.div style={{ scale: isMobile ? 1 : animation.scrollYProgress }}>
        <Top>
          <Title>TESTIMONIALS</Title>
          <Description>Our Student Testimonials</Description>
        </Top>
      </motion.div>

      <motion.div
        style={{ scale: isMobile ? 1 : animationCards.scrollYProgress }}
      >
        <List>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Rounak Singh"
          >
            Skysaid has been truly amazing. They delivered everything they
            promised when it comes to teaching and skill-building. The
            internship experience was really helpful for me. I received all the
            necessary training and even got a chance to enter the industry.
            Skysaid also supported me in securing a job and kickstarting my
            career. If you're young and struggling to figure out how to begin
            your career, you should definitely consider enrolling with them.
          </TestimonialCard>
          <TestimonialCard image="https://picsum.photos/200" name="Shanu Priya">
            Skysaid turned out to be a game-changer for me. Their approach to
            teaching and skill development exceeded my expectations. The
            internship they provided was incredibly valuable, giving me hands-on
            experience and the right guidance. Thanks to their support, I was
            able to land a job and take my first step towards building a career.
            If you're someone who's unsure about how to get started in your
            professional journey, I highly recommend joining Skysaid.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Ujjwal kumar"
          >
            Joining Skysaid was honestly one of the best decisions I made. They
            didn’t just talk the talk – they actually delivered when it came to
            practical skills and real-world learning. The internship? Super
            helpful. I got the hands-on experience I needed, and it opened doors
            for me professionally. Skysaid even helped me land my first job,
            which gave my career the kickstart it needed. If you’re feeling
            stuck or unsure about where to begin, Skysaid is definitely worth
            checking out!
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Pranav Thakur"
          >
            Skysaid completely exceeded my expectations. Their training wasn’t
            just about theory—it was all about practical skills that actually
            matter. The internship they offered gave me real-world experience,
            and with their help, I managed to secure my first job. Skysaid
            didn’t just prepare me for work, they set the foundation for my
            career. If you're feeling lost about where to begin, trust me,
            Skysaid can make a huge difference.
          </TestimonialCard>
          <TestimonialCard
            image="https://picsum.photos/200"
            name="Raghav Singh"
          >
            I can't say enough good things about Skysaid. From the start, they
            focused on building skills that are genuinely useful, not just
            textbook knowledge. The internship experience was a real eye-opener
            and gave me a solid start in the industry. What really stood out was
            how they supported me in landing my first job, making the transition
            from learning to working seamless. If you're looking for a push to
            kickstart your career, Skysaid is definitely the way to go.
          </TestimonialCard>
          <TestimonialCard image="https://picsum.photos/200" name="Neha Singh">
            Skysaid was a total game-changer for me. Their approach wasn’t just
            about learning—it was about really understanding the skills needed
            to succeed. The internship was a great opportunity to apply what I’d
            learned, and it opened doors I didn’t expect. They even guided me
            through the job hunt and helped me secure my first position. If
            you’re feeling stuck and don’t know where to begin, Skysaid is the
            place to start.
          </TestimonialCard>
        </List>
      </motion.div>
    </Container>
  );
}
