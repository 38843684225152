import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownInput from "../helperComponents/DropDownInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import ImageCropper from "../util/ImageCropper.js";
import ImageCropper2 from "../util/ImageCropper2.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 10px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 20px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
  position: absolute;
  right: -12px;
  bottom: -1px;
  width: 50px;
  background: var(--glassGradient);
  padding: 0;
  display: flex;
  border: 1px solid var(--foreground);
  backdrop-filter: blur(30px);
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 50px;
`;

export default function BasicInfoEditor({ callback }) {
  const { loggedInUser, setLoggedInUser, popupAlert, setForm } =
    useContext(Context);
  const [config, setConfig] = useState({});

  const [selectedImages, setSelectedImages] = useState(null);

  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      let newState = { ...config };
      newState = { ...loggedInUser };
      setConfig(newState);
    }
  }, [loggedInUser]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage
      height={"130px"}
      width={"130px"}
      fileObject={selectedImages[0]}
    />
  ) : (
    <ProfilePicture user={loggedInUser} height="130px" width="130px" />
  );

  return (
    <Container>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}
          <ImageUploadButton onClick={chooseImage}>
            <AiOutlineUpload />
          </ImageUploadButton>
        </ImageUploadSection>

        <MaterialInput
          value={config.name}
          onChange={makeChange("name")}
          label={"Full Name"}
        />

        <MaterialInput
          value={config.username}
          onChange={makeChange("username")}
          label={"Username"}
        />

        <MaterialInput
          value={config.tagline}
          onChange={makeChange("tagline")}
          label={"Describe yourself in 5 words or less"}
        />

        <MaterialInput
          value={config.bio}
          multiline={true}
          rows={4}
          onChange={makeChange("bio")}
          label={"Describe yourself in detail"}
        />

        <MaterialInput
          value={config.anonymousUsername}
          onChange={makeChange("anonymousUsername")}
          label={"Anonymous Username"}
        />

        {/* <MaterialInput
          value={config.qualification}
          onChange={makeChange("qualification")}
          label={"Type your qualification here"}
        />

        <MaterialInput
          value={config.workExperience}
          multiline={true}
          rows={4}
          onChange={makeChange("workExperience")}
          label={"Type your work experience here"}
        /> */}

        <DropDownLocationInput
          onChange={makeChange("country", true)}
          type="COUNTRY"
          placeholder={"Select Country"}
          value={config.country}
        />

        <DropDownLocationInput
          onChange={makeChange("state", true)}
          type="STATE"
          country={config.country}
          placeholder={"Select State"}
          value={config.state}
        />

        <DropDownLocationInput
          onChange={makeChange("city", true)}
          type="CITY"
          country={config.country}
          state={config.state}
          placeholder={"Select City"}
          value={config.city}
        />

        <CustomToggle
          name="Gender"
          options={[
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
          ]}
          value={config.gender}
          onChange={makeChange("gender", true)}
        />
        <MaterialInput
          value={config.instaUsername}
          onChange={makeChange("instaUsername")}
          label={"Instagram Username"}
        />
        <MaterialInput
          value={config.twitterUsername}
          onChange={makeChange("twitterUsername")}
          label={"Twitter Username"}
        />
        <MaterialInput
          value={config.githubUsername}
          onChange={makeChange("githubUsername")}
          label={"Github Username "}
        />
        <MaterialInput
          value={config.website}
          onChange={makeChange("website")}
          label={"Website URL"}
        />
      </Inputs>
      <br /> <br />
      <CustomButton
        style={{ width: "50%", padding: "20px 0" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="filled"
      >
        Save
      </CustomButton>
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName, isString) {
    return (e) => {
      if (isString) {
        let newItem = { ...config };
        newItem[fieldName] = e;
        setConfig(newItem);
        return true;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);
    };
  }

  function isValidLink(url) {
    var re =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(url);
  }

  async function chooseImage() {
    let selectedFiles = await selectFile({ onlyImage: true });

    if (selectedFiles) {
      if (selectedFiles.length) {
        setForm({
          title: "Crop Image",
          component: (
            <ImageCropper2
              imageBlob={selectedFiles[0]}
              onCropDone={(newImage) => {
                setForm(null);
                setSelectedImages([newImage]);
              }}
            />
          ),
        });
      }
    }
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    console.log("Uploading Image");
    let newFile = await compressAndUploadFile(null, selectedImages[0]);
    setLoading(false);
    return newFile;
  }

  function isAlphaNumeric(str) {
    return /^[a-zA-Z0-9]+$/.test(str);
  }

  async function save() {
    if (!config.anonymousUsername)
      return popupAlert(
        "Please! fill the Anonymous Username. It will be used for all your anonymous conversations."
      );
    if (!config.bio) return popupAlert("Bio is missing");
    if (!config.country) return popupAlert("Please add your country");
    if (!config.state) return popupAlert("Please add your state");
    if (!config.city) return popupAlert("Please add your city");

    if (config.username) {
      if (!isAlphaNumeric(config.username)) {
        return popupAlert(
          "The username can only contain alphabets and numbers"
        );
      }
    }

    let fileData = await uploadImage();

    let toSend = {
      changes: {},
    };

    toSend.changes.onboarding = "basicInfoAdded";

    toSend.changes.name = config.name;
    toSend.changes.bio = config.bio;
    toSend.changes.anonymousUsername = config.anonymousUsername;
    toSend.changes.instaUsername = config.instaUsername;
    toSend.changes.twitterUsername = config.twitterUsername;
    toSend.changes.githubUsername = config.githubUsername;
    toSend.changes.website = config.website;

    toSend.changes.workExperience = config.workExperience;
    toSend.changes.qualification = config.qualification;

    toSend.changes.username = config.username;
    toSend.changes.tagline = config.tagline;
    toSend.changes.description = config.description;

    toSend.changes.country = config.country;
    toSend.changes.state = config.state;
    toSend.changes.city = config.city;
    toSend.changes.skillTags = config.skillTags;

    let newConfig = { ...config };

    if (fileData) {
      if (fileData.fileName) {
        toSend.changes.profileImage = {
          type: "S3_UPLOAD",
          data: fileData.fileName,
        };

        newConfig.profileImage = toSend.changes.profileImage;
      }
    }

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser, ...newConfig };

      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.basicInfoAdded = true;
      } else {
        newLoggedInUser.onboarding = { basicInfoAdded: true };
      }

      console.log("newLoggedInUser", newLoggedInUser);
      setLoggedInUser(newLoggedInUser);

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
