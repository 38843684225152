import { useContext, useEffect, useState } from "react";
import ContentPage from "./routes/ContentPage.js";

import FeedPage from "./routes/FeedPage.js";
import SendAuthCode from "./routes/SendAuthCode.js";
import ProfileEditor from "./routes/ProfileEditor.js";
import ChatPage from "./routes/ChatPage.js";
import Notifications from "./routes/Notifications.js";
import CreateContent from "./routes/CreateContent.js";

import MoreMenu from "./routes/MoreMenu.js";
import Context from "../../Context.js";

import styled from "styled-components";
import Search from "./routes/Search.js";
import ProfilePage from "../profilePage/ProfilePage.js";
import getSubPath from "../../controllers/getSubPath.js";
import HomePage from "./routes/HomePage.js";
import WithHeader from "./WithHeader.js";
import AskPreferenceDetails from "../editors/AskPreferenceDetails.js";
import EditContent from "./routes/EditContent.js";
import ReportsDashboardPage from "../report/ReportsDashboardPage.js";
import ShowReportsPage from "../report/ShowReportsPage.js";
import CreateReport from "./routes/CreateReport.js";
import ConnectionsPage from "../report/ConnectionsPage.js";
import BanManagementDashboard from "../report/BanManagementDashboard.js";
import Blocklist from "../report/Blocklist.js";
import CreatePage from "./routes/CreatePage.js";
import getPopupType from "../../controllers/popup/getPopupType.js";
import PopupPage from "../popup/PopupPage.js";
import pathVsComp from "../../data/pathVsComp.js";
import { MdOpacity } from "react-icons/md";

const Container = styled.div`
  /* height: 100%; */
  display: flex;
  flex-direction: column;
`;

export default function CustomRouter() {
  const { currentRoute, nonPopupRoute } = useContext(Context);

  let popupComp = null;
  let baseComp = findCompOfPath(currentRoute);
  let currentPageIsPopup = getPopupType(currentRoute);

  if (currentPageIsPopup && nonPopupRoute) {
    if (currentRoute !== nonPopupRoute) {
      baseComp = findCompOfPath(nonPopupRoute);
      popupComp = getPopupComp(currentRoute);
    }
  }

  let containerStyle = null;
  if (popupComp) containerStyle = { opacity: 1 };

  return (
    <>
      <Container data-2={currentPageIsPopup} style={containerStyle}>
        {baseComp}
      </Container>
      {popupComp}
    </>
  );

  function findCompOfPath(pathToScan) {
    // console.log("Scanning Path", pathToScan);

    pathToScan = pathToScan.split("?")[0];
    if (pathToScan == "/") return pathVsComp["/"];

    for (let aPath in pathVsComp) {
      if (aPath == "/") continue;
      let theComp = pathVsComp[aPath];
      let matchLength = aPath.length;
      let extracted = pathToScan.slice(0, matchLength);
      // console.log(extracted, aPath, extracted === aPath);
      if (extracted === aPath) return theComp;
    }

    let userPageParam = getSubPath(0, pathToScan);

    return (
      <WithHeader>
        <ProfilePage username={userPageParam} />
      </WithHeader>
    );
  }

  function getPopupComp(pathName) {
    let popupType = getPopupType(pathName);
    if (popupType) {
      let element = null;

      if (popupType == "PROFILE") {
        let userPageParam = getSubPath(0, currentRoute);
        element = <ProfilePage username={userPageParam} />;
      }

      if (popupType == "CONTENT") element = <ContentPage />;

      return <PopupPage element={element} />;
    } else {
      return false;
    }
  }
}
