import styled from "styled-components";

import { GrGithub } from "react-icons/gr";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context.js";

import {
  BsInstagram,
  BsThreeDots,
  BsThreeDotsVertical,
  BsTwitterX,
} from "react-icons/bs";

import { serverLine } from "../../controllers/serverLine.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";

import ProfileSection from "./ProfileSection.js";
import SocialLink from "./SocialLink.js";

import getLocationString from "../../controllers/getLocationString.js";

import getUrlParams from "../../controllers/getUrlParams.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import { AiOutlineYoutube } from "react-icons/ai";

import PageNotFound from "../helperComponents/PageNotFound.js";
import WithHeader from "../core/WithHeader.js";

import FeedItem from "../cardForFeed/FeedItem.js";
import getImageURL from "../../controllers/getImageURL.js";
import CustomButton from "../helperComponents/CustomButton.js";
import { HiMenu } from "react-icons/hi";
import limitStringLength from "../../controllers/limitStringLength.js";
import GalleryEditorAndPresenter from "../editors/GalleryEditorAndPresenter.js";
import { FiAlertTriangle } from "react-icons/fi";
import goTo from "../../controllers/goTo.js";
import userIDsToGroupID from "../../controllers/userIDsToGroupID.js";
import BlockUnblockInterface from "./BlockUnblockInteface.js";
import DirectMessageBox from "../cardForFeed/DirectMessageBox.js";

const MainContent = styled.div`
  padding: 50px 130px;
  /* padding-top: 150px; */
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 100px;
  align-items: center;

  @media (max-width: 900px) {
    padding: 5vw;
  }
`;

const Top = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  flex-direction: row;
  /* justify-content: space-between; */
  margin-bottom: 0;
  gap: 25px;
  border-radius: 10px;
  background: var(--glassGradient);
  padding: 20px;
  border: 1px solid var(--foreground);
  box-shadow: var(--lightShadow);

  width: 100%;

  @media (max-width: 900px) {
    /* padding: 20px; */
    flex-direction: column;
    margin-top: 0;
    gap: 20px;
    /* gap: 20px; */
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  height: 100%;
  flex: 1;
`;

const TitleText = styled.div`
  font-size: 28px;
`;

const HeroTitle = styled.div`
  font-weight: 800;
  /* margin-bottom: 10px; */

  padding: 10px 30px;
  /* border-bottom: 1px solid var(--translucent); */

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const HeroDescription = styled.div`
  font-size: 18px;
  /* margin-top: -15px; */
  /* width: 600px; */
  opacity: 0.7;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
  /* line-height: 30px; */
  width: 100%;

  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  border-radius: 10px;
  gap: 10px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 18px;
    width: 100%;
  }
`;

const Bio = styled.div`
  font-size: 18px;
  opacity: 0.7;
  flex: 1;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */

  padding: 20px;

  width: 100%;

  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  box-shadow: var(--hardShadow);
  border-radius: 10px;
`;

const PreferenceSection = styled.div`
  font-size: 18px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  box-shadow: var(--hardShadow);
  border-radius: 10px;
  gap: 10px;
`;

const PreferenceRow = styled.div`
  font-size: 18px;
  opacity: 0.7;
  flex: 1;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */

  width: 100%;
`;

const HeroRight = styled.div``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const SectionTitle = styled.div`
  font-size: 22px;
  opacity: 0.5;
`;

const SectionList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const ProfileContainer = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;

const HeroButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const ProfileImage = styled.img`
  height: 170px;
  width: 170px;
  object-fit: cover;
  border-radius: 0;
  border-radius: 15px;
  box-shadow: var(--hardShadow);

  @media (max-width: 900px) {
    height: 250px;
    width: 250px;
  }
`;

const DescLine = styled.div``;

const Bold = styled.b``;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export default function ProfilePage({ username }) {
  let {
    currentRoute,
    loggedInUserID,
    loggedInUser,
    notificationData,
    setForm,
    popupAlert,
  } = useContext(Context);
  const [userData, setUserData] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // console.log(userData.user);

  useEffect(() => {
    if (userData) {
      if (userData.user) {
        if (userData.user.name) {
          document.title = userData.user.name;
        }
      }
    }
  }, [currentRoute, userData]);

  useEffect(() => {
    setUserData(null);
    serverLine
      .get("/profile/?username=" + username)
      .then(setUserData)
      .catch((err) => {
        setError(err.message);
      });
  }, [username]);

  if (error == "User not found")
    return <PageNotFound message="Page not found" />;

  if (error) return JSON.stringify(error);

  if (!userData || loading) return <LoadingSection />;

  if (!userData.user.bio) userData.user.bio = "Hi I am new to skysaid";

  let locationStr = getLocationString(userData.user);

  let heroSectionInfo = (
    <HeroLeft>
      <HeroTitle>
        <TitleText>{userData.user.name}</TitleText>

        <HeroButtons>
          <SocialLink
            baseLink="https://instagram.com"
            icon={<BsInstagram />}
            field="instagram"
            data={userData.user.instaUsername}
          />
          <SocialLink
            baseLink="https://github.com"
            data={userData.user.githubUsername}
            icon={<GrGithub />}
            field="github"
          />
          <SocialLink
            baseLink="https://twitter.com"
            data={userData.user.twitterUsername}
            icon={<BsTwitterX />}
            field="twitter"
          />
          <SocialLink
            baseLink="https://youtube.com"
            data={userData.user.youtubeUsername}
            icon={<AiOutlineYoutube />}
            field="youtube"
          />

          {getDashboardButton()}
        </HeroButtons>
      </HeroTitle>

      <HeroDescription>
        <DescLine> {limitStringLength(userData.user.tagline, 40)} </DescLine>
        <DescLine>
          @{userData.user.username} .{" "}
          {locationStr ? "  " + locationStr : "Earth"}
        </DescLine>
      </HeroDescription>
    </HeroLeft>
  );

  let heroSection = (
    <HeroSection>
      <ProfileImage src={getImageURL(userData.user.profileImage, true)} />

      {heroSectionInfo}
    </HeroSection>
  );

  function getDashboardButton() {
    if (loggedInUserID !== userData.user._id)
      return (
        <CustomButton
          style={{ fontSize: "25px" }}
          variant="minimal"
          onClick={showOptions}
          icon={<BsThreeDotsVertical />}
        />
      );

    if (notificationData.general)
      return (
        <CustomButton
          style={{
            fontSize: "13px",
            height: "30px",
            width: "30px",
            backgroundColor: "tomato",
            color: "var(--foreground)",
          }}
          variant="minimal"
          href="/more-menu"
          icon={<FiAlertTriangle />}
        />
      );

    return (
      <CustomButton
        style={{ fontSize: "25px" }}
        variant="minimal"
        href="/more-menu"
        icon={<HiMenu />}
      />
    );
  }

  let theImages = userData.user.images;
  let allowEditing = false;

  if (loggedInUser) {
    if (loggedInUser._id == userData.user._id) {
      allowEditing = true;
    }
  }

  return (
    <MainContent id="heroSection">
      <Top>
        {heroSection}

        {userData.user.bio ? (
          <Bio>
            {" "}
            <Bold> Bio: </Bold>
            {userData.user.bio}
          </Bio>
        ) : null}

        {userData.user.qualification && userData.user.workExperience ? (
          <Bio>
            <Bold> Qualification & Work Experience: </Bold>
            {userData.user.qualification}. {userData.user.workExperience}
          </Bio>
        ) : null}

        <GalleryEditorAndPresenter
          setUserData={setUserData}
          userData={userData}
          images={theImages}
          allowEditing={allowEditing}
        />
        {getPreferenceSection()}
        <DirectMessageBox item={userData.user} />
      </Top>
      <ProfileSection data={userData.contents} userData={userData.user} />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
    </MainContent>
  );

  function showOptions() {
    let options = [];

    if (!loggedInUserID) return popupAlert("Login is required");

    options = [
      {
        name: "Report User",
        onClick: goTo(
          `/create-report/?subjectType=USER&subjectID=${userData.user._id}`
        ),
      },
      { name: "Manage Blocking", onClick: manageBlocking },
    ];

    setForm({ options: options });
  }

  function manageBlocking() {
    setForm({
      title: "Manage Blocking",
      component: (
        <BlockUnblockInterface
          receiverUserData={userData.user}
          receiverUserID={userData.user._id}
          senderUserID={loggedInUserID}
          senderIdentityType={"IDENTIFIABLE"}
          receiverIdentityType={"IDENTIFIABLE"}
        />
      ),
    });
  }

  function getPreferenceSection() {
    let content = [];

    if (userData.user.favoriteSeries) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Series:</Bold> {userData.user.favoriteSeries}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.favoriteMovies) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Movies:</Bold> {userData.user.favoriteMovies}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.favoriteSingers) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Singers:</Bold> {userData.user.favoriteSingers}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.favoriteBooks) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Books:</Bold> {userData.user.favoriteBooks}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.favoriteFoods) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Foods:</Bold> {userData.user.favoriteFoods}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.favoriteSports) {
      content.push(
        <PreferenceRow>
          <Bold>Favorite Sports:</Bold> {userData.user.favoriteSports}{" "}
        </PreferenceRow>
      );
    }

    if (userData.user.celebrityCrush) {
      content.push(
        <PreferenceRow>
          <Bold>Celebrity Crush:</Bold> {userData.user.celebrityCrush}{" "}
        </PreferenceRow>
      );
    }

    if (!content.length) return null;

    return <PreferenceSection> {content} </PreferenceSection>;
  }
}
