import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";

const Container = styled.div`
  padding: 10px;
`;

export default function ReportsDataBox({ item, subjectType }) {
  return (
    <Container>
      <CustomButton
        style={{ borderRadius: "10px" }}
        variant="filled"
        href={`/show-reports/?subjectID=${item._id}&subjectType=${subjectType}`}
      >
        Show All Reports (New:{item.newReportsCount}, Old:
        {item.oldReportsCount})
      </CustomButton>
    </Container>
  );
}
