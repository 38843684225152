import getOppositeMemberID from "../getOppositeMemberID";

export default function getOnlineOfflineStatus({
  friendsLastSeenAt,
  oppositeMemberID,
}) {
  if (!friendsLastSeenAt) return false;

  if (friendsLastSeenAt[oppositeMemberID]) {
    let time = new Date(friendsLastSeenAt[oppositeMemberID]).getTime();
    let now = new Date().getTime();

    if (now - time < 10000) {
      return "online";
    }
  }

  return false;
}
