export default function reachChatPanelEnd() {
  theFunction();

  window.setTimeout(theFunction, 500);
}

function theFunction() {
  const element = document.getElementById("chat-panel");
  if (!element) return;
  element.scrollTop = element.scrollHeight;
}
