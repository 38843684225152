import { useContext, useEffect, useState } from "react";
import WithHeader from "../core/WithHeader";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import UserBox from "../profilePage/UserBox";
import styled from "styled-components";
import Context from "../../Context";
import getUrlParams from "../../controllers/getUrlParams";
import CustomToggle from "../helperComponents/CustomToggle";
import CustomButton from "../helperComponents/CustomButton";
import BlockUnblockInterface from "../profilePage/BlockUnblockInteface";
import InfoBox from "../util/InfoBox";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  gap: 20px;
`;

let options = [
  { value: "NORMAL_BLOCK", label: "Normal Blocks" },
  { value: "ANONYMOUS_BLOCK", label: "Anonymous Blocks" },
];

export default function Blocklist() {
  const { setForm, loggedInUserID } = useContext(Context);

  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    serverLine.get("/blocklist").then((newData) => {
      setData(newData);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let list = data.map((item) => getItem(item));

  if (!list.length)
    list = <InfoBox>You have not blocked anyone who is not anonymous</InfoBox>;

  return (
    <WithHeader>
      <Container>
        <Main>
          <BeautifulTitle>People you have blocked</BeautifulTitle>
          <List>{list}</List>
        </Main>
      </Container>
    </WithHeader>
  );

  function getItem(item) {
    if (item.receiverUser) {
      return (
        <UserBox
          onClick={manageBlocking({
            userData: item.receiverUser,
            userID: item.receiverUserID,
            isAnonymous: false,
          })}
          item={item.receiverUser}
        />
      );
    }
  }

  function manageBlocking({ userID, userData }) {
    return () => {
      setForm({
        title: "Manage Blocking",
        component: (
          <BlockUnblockInterface
            receiverUserData={userData}
            receiverUserID={userID}
            senderUserID={loggedInUserID}
            senderIdentityType={"IDENTIFIABLE"}
            receiverIdentityType={"IDENTIFIABLE"}
          />
        ),
      });
    };
  }
}
