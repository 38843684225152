export default function getUserIdentityType({ chatGroup, userID }) {
  if (chatGroup.contentType == "ANONYMOUS") return "ANONYMOUS";

  if (chatGroup.type == "ANONYMOUS_DIRECT_CHAT") {
    let initiatorUserID =
      chatGroup.anonymousDirectChatInitiatorUserID.toString();

    userID = userID.toString();
    if (userID == initiatorUserID) return "ANONYMOUS";
  }

  return "IDENTIFIABLE";
}
