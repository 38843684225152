import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";
import {
  MdOutlineArticle,
  MdOutlineBeachAccess,
  MdWorkOutline,
} from "react-icons/md";

import Context from "../../../Context";

import LoadingSection from "../../helperComponents/LoadingSection";
import MaterialInput from "../../helperComponents/MaterialInput";
import { AiOutlineSend } from "react-icons/ai";
import DropDownInput from "../../helperComponents/DropDownInput";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { MoonLoader } from "react-spinners";
import contentCategories from "../../../data/contentCategories";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  overflow: hidden;
  width: 38vw;
  padding: 0;
  border-radius: 10px;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  box-shadow: 1px 1px 20px 1px #00000024;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Title = styled.h3`
  padding: 20px 0;
  margin: 0;
  font-size: 19px;
  text-align: center;
  font-weight: 800;
  font-family: "Raleway", sans-serif;
  border-bottom: 1px solid var(--translucentHard);
`;

const JustText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
  width: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  align-items: center;
  /* align-items: center; */
  width: 100%;
  height: 75px;
  padding: 0 20px;
  justify-content: space-between;

  border-top: 1px solid var(--translucent);
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;

  gap: 25px;
`;

const SelectCategoryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-left: 20px;
  padding-right: 200px;
  border-right: 1px solid var(--translucentHard);
`;

const WriteTitle = styled.textarea`
  height: 90px;
  resize: none;
  width: 100%;
  border: none;
  color: var(--color);
  padding: 25px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  /* text-align: center; */
`;

const PostButton = styled.button`
  height: 40px;
  width: 60px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding: 0 0;
  gap: 10px;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  color: var(--color);
  background-color: var(--foreground);
  /* border: 1px solid var(--translucentHard); */
  border-radius: 10px;
  box-shadow: var(--lightShadow);
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(0.9);
  }
`;

const PostButtonText = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const PostButtonIcon = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

let dropDownInputStyle = {
  height: "40px",
  width: "auto",
  border: "none",
  boxShadow: "var(--lightShadow)",
};

let workOptions = [
  { label: "Looking for work", value: "WORK_SEEKER" },
  { label: "Looking for talent", value: "RECRUITER" },
];

export default function CreateContent({ type, contentToUpdate }) {
  const { loggedInUser, popupAlert } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [workType, setWorkType] = useState();

  if (contentToUpdate) type = contentToUpdate.type;

  useEffect(() => {
    if (contentToUpdate) {
      setTitle(contentToUpdate.title);
      setCategory(contentToUpdate.category);
    }
  }, []);

  console.log("contentToUpdate", contentToUpdate, title);

  if (!loggedInUser)
    return <ShimmerThumbnail height={250} width={"100%"} rounded />;

  function isLoading() {
    if (loading || !loggedInUser) return true;
    return false;
  }

  return (
    <Container>
      <WriteTitle
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        placeholder={getPlaceholder()}
      />

      <Row>
        <Left>
          <DropDownInput
            style={dropDownInputStyle}
            placeholder="Select Category"
            options={contentCategories[type]}
            value={category}
            onChange={setCategory}
          />

          {type == "WORK" ? (
            <DropDownInput
              style={dropDownInputStyle}
              placeholder="Select Type"
              options={workOptions}
              value={workType}
              onChange={setWorkType}
            />
          ) : null}
        </Left>

        <PostButton onClick={create}>
          {isLoading() ? (
            <MoonLoader size={17} />
          ) : (
            <>
              <PostButtonIcon>
                <AiOutlineSend />
              </PostButtonIcon>
            </>
          )}
        </PostButton>
      </Row>
    </Container>
  );

  function create() {
    if (isLoading()) return false;

    if (!category) return popupAlert("Please select a category");

    if (type == "DATING" && !loggedInUser.onboarding.datingInfoAdded) {
      return popupAlert("Please! Complete your dating profile first.");
    }

    if (type == "WORK" && !workType)
      return popupAlert("Please select if you are looking for talent or work");

    setLoading(true);

    if (contentToUpdate) {
      serverLine
        .patch("/content", {
          contentID: contentToUpdate._id,
          changes: {
            category: category,
            title: title,
          },
        })
        .then((newData) => {
          setLoading(false);
          goTo("/content/" + newData._id)();
        });
    } else {
      try {
        serverLine
          .post("/content", {
            values: {
              category: category,
              title: title,
              type: type == "WORK" ? workType : type,
            },
          })
          .then((newData) => {
            setLoading(false);
            goTo("/content/" + newData._id)();
          });
      } catch (e) {
        popupAlert(e.message);
        setLoading(false);
      }
    }
  }

  function getPlaceholder() {
    if (type == "GENERAL") {
      return "What would you like to talk about?";
    } else if (type == "DATING") {
      return "Write something that shows your character and personality.";
    } else if (type == "ANONYMOUS") {
      return "You identity will be kept hidden but you can still get banned for writing inappropriate things like hate speech / erotic / disgusting ";
    } else if (type == "WORK") {
      return "If you looking for work, write about that. If you are looking for talent, write about that.";
    }
  }
}
