import styled from "styled-components";
import WhyChooseUsLine from "./WhyChooseUsLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;

  @media (max-width: 900px) {
    width: 90vw;
    align-items: center;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 55px;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 30px;
    width: 82vw;
    text-align: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 900px) {
    align-items: center;
  }
`;

export default function WhyChooseUsText() {
  return (
    <Container>
      <Tagline>
        Become A Professional <br /> With Our
      </Tagline>
      <Buttons>
        <WhyChooseUsLine> Distinguished Mentors </WhyChooseUsLine>
        <WhyChooseUsLine>Industrial Projects</WhyChooseUsLine>
        <WhyChooseUsLine>Live Interactive Session</WhyChooseUsLine>
        <WhyChooseUsLine>Internship Experience</WhyChooseUsLine>
      </Buttons>
      <br />
    </Container>
  );
}
