import { styled } from "styled-components";

import { serverLine } from "../../../controllers/serverLine";
import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import Context from "../../../Context";

import getSubPath from "../../../controllers/getSubPath";
import RenderContent from "../../contentPage/RenderContent";
import LoadingSection from "../../helperComponents/LoadingSection";
import CreateContent from "./CreateContent";
import BeautifulTitle from "../../helperComponents/BeautifulTitle";
import { MdOutlineEdit } from "react-icons/md";
import FeedItem from "../../cardForFeed/FeedItem";
import MaterialInput from "../../helperComponents/MaterialInput";
import CustomButton from "../../helperComponents/CustomButton";
import getUrlParams from "../../../controllers/getUrlParams";
import UserBox from "../../profilePage/UserBox";
import InfoBox from "../../util/InfoBox";
import FeedItemMini from "../../cardForFeed/FeedItemMini";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 45vw;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export default function CreateReport() {
  const { currentRoute, popupAlert } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [reportSent, setReportSent] = useState(false);

  let urlQuery = getUrlParams(currentRoute);
  let subjectID = urlQuery.get("subjectID");
  let subjectType = urlQuery.get("subjectType");

  useEffect(() => {
    if (subjectType == "CONTENT") {
      serverLine.get("/content/?contentID=" + subjectID).then((newData) => {
        setItemData(newData);
        setLoading(false);
      });
    } else if (subjectType == "USER") {
      serverLine.get("/profile/?userID=" + subjectID).then((newData) => {
        if (newData.user) {
          setItemData(newData.user);
        }

        setLoading(false);
      });
    }
  }, [subjectID, subjectType]);

  if (!itemData || loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  if (reportSent)
    return (
      <WithHeader>
        <Container>
          <Main>
            <BeautifulTitle>Your report has been sent</BeautifulTitle>

            <InfoBox>
              We will review it and take action as soon as possible. Thankyou
              for reporting it. We are extremely sorry for the offense caused by
              the author of this post.
            </InfoBox>
          </Main>
        </Container>
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <Main>
          <BeautifulTitle icon={<MdOutlineEdit />}>
            Report {subjectType == "USER" ? "User" : "Content"}
          </BeautifulTitle>
          {subjectType == "CONTENT" ? (
            <FeedItemMini item={itemData} />
          ) : (
            <UserBox item={itemData} />
          )}
          <Inputs>
            <MaterialInput
              value={reason}
              label="Type reason in 5 words or less"
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />

            <MaterialInput
              value={description}
              multiline={true}
              rows={3}
              label="Type reason in detail"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />

            <CustomButton
              style={{ borderRadius: "10px", width: "200px" }}
              variant="filled"
              onClick={sendReport}
            >
              Send Report
            </CustomButton>
          </Inputs>
          <br /> <br /> <br /> <br /> <br /> <br />
        </Main>
      </Container>
    </WithHeader>
  );

  async function sendReport() {
    if (!reason || !description)
      return popupAlert("Please fill all the required fields");

    setLoading(true);

    try {
      await serverLine.post("/report", {
        subjectID: subjectID,
        subjectType,
        reason,
        description,
      });

      setReportSent(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
