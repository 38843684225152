import styled from "styled-components";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { HiHeart, HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext, useState } from "react";
import Context from "../../../Context.js";

import isLoggedIn from "../../../controllers/isLoggedIn.js";

import isSubDomain from "../../../controllers/getSubdomain.js";

import getSubdomain from "../../../controllers/getSubdomain.js";
import WithHeader from "../WithHeader.js";

import LoggedOutHomePage from "../LoggedOutHomePage.js";
import ProfilePage from "../../profilePage/ProfilePage.js";
import MainFeed from "../../feed/MainFeed.js";
import CreateContent from "./CreateContent.js";
import BeautifulTitle from "../../helperComponents/BeautifulTitle.js";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { PiFeatherFill, PiHandshake, PiMaskHappy } from "react-icons/pi";
import { GoBriefcase } from "react-icons/go";
import { FiHeart } from "react-icons/fi";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import AskPreferenceDetails from "../../editors/AskPreferenceDetails.js";
import InfoBox from "../../util/InfoBox.js";
import CustomToggle from "../../helperComponents/CustomToggle.js";

const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid;
  gap: 25px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 300px;
  }
`;

const BigButton = styled(Button)`
  width: auto;
  padding: 0 50px;
  flex: unset;
  border: none;
  height: 60px;
  box-shadow: 3px 5px 8px 0px #00000066;

  @media (max-width: 700px) {
    width: calc(100vw - 50px);
  }
`;

const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  padding: 10px 130px;
  padding-top: 20px;
  gap: 70px;

  @media (max-width: 900px) {
    padding: 0;
    margin-top: 30px;
    gap: 30px;
  }
`;

const options = [
  { label: "Friendship", value: "GENERAL" },
  { label: "Love", value: "DATING" },
  { label: "Anonymous", value: "ANONYMOUS" },
];

export default function CreatePage() {
  const { loggedInUser } = useContext(Context);
  const [type, setType] = useState("GENERAL");
  if (!isLoggedIn()) return <LoggedOutHomePage />;

  if (!loggedInUser)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  if (loggedInUser.banned)
    return (
      <WithHeader>
        <InfoBox>
          You can't access this page because you have been banned.
        </InfoBox>
      </WithHeader>
    );

  if (type == "DATING") {
    let datingOnBoarding = (
      <WithHeader>
        <AskPreferenceDetails />
      </WithHeader>
    );

    if (!loggedInUser.images) {
      return datingOnBoarding;
    }

    if (loggedInUser.images.length < 3) {
      return datingOnBoarding;
    }

    if (!loggedInUser.favoriteSeries || !loggedInUser.favoriteMovies) {
      return datingOnBoarding;
    }
  }

  return (
    <WithHeader>
      <Main>
        {/* <BeautifulTitle icon={<PiFeatherFill />}>
          Create a chat proposal
        </BeautifulTitle> */}

        <CustomToggle onChange={setType} value={type} options={options} />
        <CreateContent type={type} />
      </Main>
    </WithHeader>
  );
}
