import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CustomButton from "../helperComponents/CustomButton";
import styled from "styled-components";
import { MdDone } from "react-icons/md";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  align-items: center;
`;

const ImageCropper = ({ imageBlob, onCropDone }) => {
  const cropperRef = useRef(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [objectURL, setObjectURL] = useState(null);

  useEffect(() => {
    setObjectURL(createObjectURL(imageBlob));
  }, []);

  const onCrop = () => {
    const cropper = cropperRef.current.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      if (blob) {
        blob.name = imageBlob.name;
      }

      setCroppedBlob(blob);
    });
  };

  const handleDone = () => {
    if (croppedBlob) {
      onCropDone(croppedBlob);
    } else {
      const cropper = cropperRef.current.cropper;
      cropper.getCroppedCanvas().toBlob((blob) => {
        blob.name = imageBlob.name;
        onCropDone(blob);
      });
    }
  };

  const createObjectURL = (blob) => {
    if (blob) {
      return URL.createObjectURL(blob);
    }
    return "";
  };

  return (
    <Container>
      <Cropper
        src={objectURL}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={1}
        aspectRatio={1}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
      />
      <CustomButton
        variant="filled"
        style={{ width: "100px" }}
        onClick={handleDone}
        icon={<MdDone />}
      >
        Done
      </CustomButton>
    </Container>
  );
};

export default ImageCropper;
